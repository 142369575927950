<div class="cabecalho">
  <span class="titulo">
    {{ data.dsTitulo }}
    <em class="fas fa-info-circle info" [pTooltip]="'dashboard.tooltip_' + data?.dsChave | translate"></em>
  </span>
  @if (isFilter) {
  <em (click)="abrirFiltro(opFiltroGrafico, $event)" class="material-icons green icone-filtro-grafico"
    >filter_list</em
  >
  } @if (exibirExpandido) {
  <button
    type="button"
    pButton
    class="p-button-rounded p-button-text botao-expandir"
    [pTooltip]="'expandir' | translate"
    (click)="expandir.emit($event)"
  >
    <span class="material-icons"> open_in_full </span>
  </button>
  }
</div>
@if (totalQueixas) {
<div class="total-container">
  <span> Total de registros: {{ totalQueixas }} </span>
</div>
} @if (isFilter&&model!=null) {
<div style="margin-top: 5px; margin-bottom: 5px">
  <span class="item-filtrado"
    >{{ model.nome }}<em (click)="limparFiltro()" class="material-icons icone-fechar">cancel</em></span
  >
</div>
}
<div class="container-geral">
  <div [chart]="chart" class="container-grafico"></div>
</div>

<p-overlayPanel #opFiltroGrafico>
  <div style="padding-top: 10px; padding-bottom: 5px">
    <label style="color: #8895ab; size: 14px">Parâmetro</label>
  </div>
  <app-dropdown
    (onChange)="onSelectItem($event, opFiltroGrafico)"
    [options]="opcoesFiltrosGrafico"
    [(ngModel)]="model"
  >
  </app-dropdown>
</p-overlayPanel>
