import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import html2canvas from "html2canvas";
import { KeycloakService } from "keycloak-angular";
import moment from "moment";
import { SelectItem, TreeNode } from "primeng/api";
import { TabPanel, TabView } from "primeng/tabview";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { UsuarioService } from "src/app/shared/api-services/usuario.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { SimNaoEnum } from "src/app/shared/enums/sim-nao-enum";
import { TipoMessageEnum } from "src/app/shared/enums/tipo-message.enum";
import { Column } from "src/app/shared/models/column.model";
import { DetalheAcaoADefinir } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { FiltroIndicadorFadiga } from "src/app/shared/models/filtro-indicador-fadiga";
import { GraficoDashboard } from "src/app/shared/models/grafico-dashboard.model";
import { ResponseResumoTestesExecutadosDia } from "src/app/shared/models/response-resumo-testes-executados-dia.to";
import { ResponseResumoUsuarioPendenteDiaTO } from "src/app/shared/models/response-resumo-usuario-pendente-dia-to";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { MessageService } from "src/app/shared/services/message.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { TipoDispositivoService } from "src/app/shared/services/tipo-dispositivo.service";
import { ResponseConfiguracaoTO } from "src/app/shared/tos/response-configuracao.to";
import { ResponseContratoHierarquiaTO } from "src/app/shared/tos/response-contrato-hierarquia.to";
import { ResponseHierarquiaTO } from "src/app/shared/tos/response-hierarquia.to";
import { ResponseGraficoIndicadorFadigaTO } from "src/app/shared/tos/response/response-grafico-indicador-fadiga.to";
import { ResponseGraficoSegurancaPsicologicaTO } from "src/app/shared/tos/response/response-grafico-segurancao-psicologica.to";
import { ResponseResumoAvaliacoesRealizadasDiaTO } from "src/app/shared/tos/response/response-resumo-avaliacoes-realizadas-dia-to";
import { ResumoDadosGrafico } from "src/app/shared/tos/response/response-resumo-dados-grafico.to";
import { ResponseResumoDadosQueixasPaginadoTO } from "src/app/shared/tos/response/response-resumo-dados-queixas-paginado-to";
import { ResponseUsuarioSimplesTO } from "src/app/shared/tos/response/response-usuario-simple.to";

import { CardChartDashboardComponent } from "./components/card-chart-dashboard/card-chart-dashboard.component";
import { SimpleCrudPage } from "../simple-crud-page";
import { FiltrosDashboardComponent } from "./filtros-dashboard/filtros-dashboard.component";
import { RoleService } from "src/app/shared/services/role.service";
import { SinalizadorProntidaoComponent } from "./components/sinalizador-prontidao/sinalizador-prontidao.component";
import { UserGuideService } from "src/app/shared/services/user-guide.service";
import { dashboardTutorial } from "./dashboardTutorial";
import { UserGuideEnum } from "src/app/shared/enums/user-guide.enum";
import { NovidadesService } from "src/app/shared/api-services/novidades.service";
import { ClienteUtil } from "src/app/shared/util/cliente.util";
import { FiltroDashboardFacade } from "./facades/filtro-dashboard.facade.service";
import { ItemGraficoDashboard, LISTA_GRAFICO_DASHBOARD } from "./lista-graficos-dashboard";
import { combineLatest, merge, zip } from "rxjs";
import { Util } from "src/app/shared/services/util.service";
import { IndicadoresFadigaComponent } from "./indicadores-fadiga/indicadores-fadiga.component";
import { tap } from "rxjs/operators";
import { ResponseResumoUsuarioTO } from "src/app/shared/tos/response/response-resumo-usuario.to";

/**
 * Tela de Dashboard do sistema.
 *
 */

@Component({
  selector: "app-dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss", "dashboard-print.component.scss"],
  providers: [UserGuideService, DatePipe],
})
export class DashboardComponent extends SimpleCrudPage implements OnInit, AfterViewInit {
  @ViewChild(FiltrosDashboardComponent) filtrosDashboardComponent: FiltrosDashboardComponent;
  @ViewChild("tabProntidao") tabProntidao: TabPanel;
  @ViewChild("tabIntrajornada") tabIntrajornada: TabPanel;
  @ViewChild("tabSeguranca") tabSeguranca: TabPanel;
  @ViewChild("tabFadiga") tabFadiga: TabPanel;
  @ViewChild("tabSinalizadorProntidao") tabSinalizadorProntidao: TabPanel;
  @ViewChild("sinalizadorProntidao") sinalizadorProntidao: SinalizadorProntidaoComponent;
  @ViewChild("indicadorFadiga") indicadorFadiga: IndicadoresFadigaComponent;
  @ViewChildren(CardChartDashboardComponent) charts: QueryList<CardChartDashboardComponent>;
  loaderResumo: boolean;
  loaderDetalhe: boolean;
  usuarioLogado: string;
  chart: Chart;
  idTesteExecutado: number;
  detalheAcaoADefinir: DetalheAcaoADefinir;
  ExibirHierarquia: boolean = false;

  cars: any[];
  cols: any[];
  title: string;
  cities: any[];
  name: string;
  code: string;
  opcoesFiltrosGrafico: SelectItem[] = [];
  recebeLoadButton: boolean = false;
  model: any;
  dadosFiltro: any = {};
  tituloGraficoBemEstar: any;
  dadosFiltroGraficoDashboard: FiltroGraficoDashboad = new FiltroGraficoDashboad();
  // dadosFiltroGraficoDashboardFiltro: FiltroGraficoDashboad = new FiltroGraficoDashboad();
  labelCargo: boolean = true;
  labelHierarquiaNaoSelecionadaFiltro: boolean = true;
  labelHierarquiaNaoSelecionada: boolean = true;

  cargoNodeSelected: TreeNode = {};
  hierarquiaSelecionada: TreeNode = {};

  showDialogFiltroGrafico: boolean;
  showDialogDefinirAcaoEmLote: boolean;
  yearRange: string;
  resumo: ResponseResumoTestesExecutadosDia = new ResponseResumoTestesExecutadosDia();
  showBotaoFiltro: boolean = true;
  exibirGraficoAnaliseProntidao: boolean;

  isExibeMensagemAvaliacaoPendente: boolean;
  acaoSugeridaSelecionada: any = null;
  listaAvaliacoesPendentes: ResponseResumoUsuarioPendenteDiaTO[] = [];
  listaUsuariosCadastrados: ResponseResumoUsuarioPendenteDiaTO[] = [];
  listaAvaliacoesRealizadas: ResponseResumoAvaliacoesRealizadasDiaTO[] = [];
  listaGraficoBemEstar: ResponseResumoDadosQueixasPaginadoTO;
  listaPopupResumo: ResumoDadosGrafico[];
  exibirListaUsuariosPendentes: boolean = false;
  exibirListaUsuariosCadastrados: boolean = false;
  exibirListaAvaliacoesRealizadas: boolean = false;
  exibirResumoPopup: boolean = false;
  showColumaDataPopupResumo: boolean = false;
  labelDataUltimoTeste: string;

  qtUsuariosPendentes: number;
  colunasUsuariosPendentes: Column[] = [];
  colunasUsuariosCadastrados: Column[] = [];
  colunasAvaliacoesRealizadas: Column[] = [];
  carregandoTabelaUsuariosPendentes: boolean = false;
  sourceObservable: any;
  sourceObservableUsuariosCadastrados: any;
  sourceObservableAvaliacoesRealizadas: any;
  sourceObservableGraficoQueixas: any;
  nrPaginaCadastrados: number = 1;
  nrPaginaRealizadas: number = 1;
  nrPaginaPendentes: number = 1;
  nrPaginaGraficoBemEstar: number = 1;
  ExibirInformacaoUnica: boolean = false;
  botaoGraficoAcaoPendente: boolean = false;

  minimoDiaMes: any;

  idAcaoSugeridaPendente: any = null;
  idAcaoDefinidaSelecionado: any = null;
  idTurnoSelecionado: any = null;
  idCargoSelecionado: any = null;
  habilitarConsulta: boolean = true;
  showDialogAcaoPendente: boolean;
  dsHierarquiaSelecionada: any = "";

  resetarHierarquia: boolean;

  contador = 1;
  resumoDadosQueixa: any;

  detalheAcaoADefinirUnico: DetalheAcaoADefinir;

  flIntraJornada: boolean;
  readOnly = false;
  todasHierarquias: ResponseHierarquiaTO[];
  exibirFiltroMatricula: boolean = true;
  hierarquias: TreeNode[] = [];
  responseSegurancaPsicologica: ResponseGraficoSegurancaPsicologicaTO[];
  responseFadiga: ResponseGraficoIndicadorFadigaTO[];
  tabFadigaSelected = false;
  configuracoes: ResponseContratoHierarquiaTO;

  tituloResumo: string;

  listaGraficosExibir: ItemGraficoDashboard[] = [];
  activeTab: number = 0;
  tutorialConfigurado = false;

  exibirAbaSegurancaPsicologica: boolean = false;
  exibirAbaSinalizadorProntidao: boolean = false;
  exibirAbaFadiga: boolean = false;

  constructor(
    private activatedRouter: ActivatedRoute,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private hierarquiaService: HierarquiaService,
    private render2: Renderer2,
    private keyCloakService: KeycloakService,
    private routerService: RouterService,
    public readonly usuarioService: UsuarioService,
    private contratoService: ContratoService,
    private messageService: MessageService,
    private tipoDispositivoService: TipoDispositivoService,
    private userGuideService: UserGuideService,
    private roleService: RoleService,
    private datePipe: DatePipe,
    private novidadesService: NovidadesService,
    private filtroDashboardFacade: FiltroDashboardFacade
  ) {
    super(activatedRouter);
  }

  async ngOnInit() {
    this.isReadOnly();
    this.configurarPorPermissao();
    if (this.keyCloakService.getUserRoles().includes("RL_SUPERVISOR_CHECKLIST")) {
      this.routerService.toGerarRelatorio();
    } else {
      this.detalheAcaoADefinirUnico = new DetalheAcaoADefinir();
      await this.initFiltroHierarquiaDoUsuario();
      await this.isSegurancaPsicologicaHabilitado();
      this.contratoService.getContrato().subscribe(
        (r) => {
          this.configuracoes = r;
          this.configurarExibirAbas();
          this.loadConfigJornada();
          this.loadDadosDashboard();
          this.yearRange = new Date().getFullYear() - 3 + ":" + new Date().getFullYear();
          this.carregarDataMinima();
          this.configurarTabela();
          this.configurarTabelaUsuariosTotal();
          this.configurarTabelaAvaliacoesRealizadas();
          this.atualizarSourceObservable();
          this.atualizarSourceObservableUsuariosCadastrados();
          this.atualizarSourceObservableAvaliacoesRealizadas();
        },
        (err) => {
          console.log("Erro ao recuperar configurações", err);
          throw err;
        }
      );
    }
  }

  async ngAfterViewInit() {
    const hasConfiguracoes = await Util.waitUntil(() => !!this.configuracoes);
    const continuarTutorial = this.activatedRouter.snapshot.queryParams["tutorial"] == "true";
    if (hasConfiguracoes) {
      this.tutorialConfigurado = true;
      this.configTutorial(this.configuracoes);
      if (continuarTutorial) {
        this.onRepetirTutorial();
      }
    }
  }

  initFiltroHierarquiaDoUsuario() {
    return new Promise((res, rej) => {
      const idHierarquiaUsuario = this.keyCloakService.getKeycloakInstance().tokenParsed["hierarquia.id"];
      const isGestor = this.keyCloakService
        .getUserRoles()
        .some((userPermission) => userPermission === Permissao.GESTOR);
      this.hierarquiaService.getAll().subscribe(
        (result) => {
          this.hierarquias = this.hierarquiaService.createTreeView(
            result,
            undefined,
            isGestor ? idHierarquiaUsuario : null
          );
          this.dadosFiltroGraficoDashboard.idHierarquia = this.hierarquiaService.getHierarquiasUsuario();
          this.todasHierarquias = result;
          if (this.detalheAcaoADefinirUnico) {
            this.detalheAcaoADefinirUnico.dsHierarquia = this.hierarquiaService
              .getAllHierarquiasTemporarias(result)
              .map((h) => h.dsHierarquia);
          }
          res(true);
        },
        (err) => {
          rej(err);
        }
      );
    });
  }

  configurarPorPermissao() {
    const isGestor = this.keyCloakService.getUserRoles().includes(Permissao.GESTOR);
    const isSupervisorChecklist = this.keyCloakService.getUserRoles().includes("RL_SUPERVISOR_CHECKLIST");
    if (isGestor) {
      this.exibirFiltroMatricula = false;
    }
    if (!isSupervisorChecklist) {
      this.dadosFiltroGraficoDashboard.flIntrajornada = SimNaoEnum.NAO;
    }
  }

  private isReadOnly() {
    this.readOnly = this.keyCloakService.getUserRoles().includes(Permissao.GESTOR);
  }

  exibirUsuarioCadastrado(): boolean {
    this.atualizarSourceObservableUsuariosCadastrados();
    this.exibirListaUsuariosCadastrados = true;
    return true;
  }

  async loadDadosDashboard() {
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    this.filtroDashboardFacade.executarFiltros("DASH", filtros);
    this.atualizarSourceObservable();
    this.atualizarSourceObservableUsuariosCadastrados();
    this.atualizarSourceObservableAvaliacoesRealizadas();
    this.exibirInformacoesTela();
    this.carregarGraficos(filtros);
    this.carregarResumo(filtros);
    this.filtrosDashboardComponent?.fecharDialogoFiltroGrafico();
    this.detalheAcaoADefinirUnico.dsHierarquia = this.todasHierarquias
      ?.filter((h) => !!filtros.idHierarquia.find((filtroHierarquia) => filtroHierarquia == h.id))
      ?.map((item) => item.dsHierarquia);
  }

  async isSegurancaPsicologicaHabilitado() {
    try {
      const hasPermission = this.roleService.isRole("RL_VISUALIZAR_SEGURANCA_PSICOLOGICA");
      const idsHierarquia = this.hierarquiaService.getHierarquiasUsuario();
      this.exibirAbaSegurancaPsicologica =
        hasPermission &&
        (await this.hierarquiaService.isSegurancaPsicologicaHabilitado(idsHierarquia).toPromise());
    } catch (err) {
      console.error("Erro ao verificar aba segurança psicologica", err);
      this.exibirAbaSegurancaPsicologica = false;
    }
  }

  carregarResumo(filtros) {
    this.loaderResumo = true;
    this.dashboardService.carregarResumo(filtros).subscribe(
      (resp) => {
        this.loaderResumo = false;
        this.resumo = resp;
        if (resp.usuario) {
          this.habilitarConsulta = true;
          this.carregarInformacaoUsuario(resp.usuario);
        } else {
          this.habilitarConsulta = true;
          this.ExibirInformacaoUnica = false;
          this.botaoGraficoAcaoPendente = false;
        }
      },
      (err) => {
        this.loaderResumo = false;
        throw err;
      }
    );
  }

  onRepetirTutorial() {
    this.configTutorial(this.configuracoes);
    this.userGuideService.repeat();
  }

  async startTutorial(novidadesExibidas: boolean) {
    if (novidadesExibidas == false) {
      await Util.waitUntil(() => this.tutorialConfigurado);
      this.userGuideService.start(UserGuideEnum.DASHBOARD);
    }
  }

  configurarExibirAbas() {
    this.exibirAbaSinalizadorProntidao =
      this.roleService.isRole("RL_VISUALIZAR_SINALIZADOR_PREVENCAO") &&
      this.configuracoes?.flExibeSinalizadorProntidao == "SIM";

    this.exibirAbaFadiga =
      this.roleService.isRole("RL_VISUALIZAR_INDICADOR_FADIGA") && this.configuracoes?.flExibeFadiga;
  }

  configTutorial(configuracoes: ResponseContratoHierarquiaTO) {
    const changeTab = (index: number) => {
      if (this.activeTab != index) {
        this.activeTab = index;
        setTimeout(() => {
          this.handleChange();
        });
      }
    };

    const isGraficosLoaded = () =>
      this.listaGraficosExibir?.every((grafico) => grafico.hasError || !!grafico.dados);

    const isResumoLoaded = () => !this.loaderResumo;

    const carregarDadosSinalizadorPrevencao = async (): Promise<boolean> => {
      await Util.waitUntil(() => !!this.sinalizadorProntidao);
      return (await this.sinalizadorProntidao.acessarPrimeiroElemento()) as any;
    };

    const carregarDadosIndicadorFadiga = () => this.indicadorFadiga.acessarPrimeiroCard();

    const navegarAcoesPendentesMobile = () => this.routerService.toListaTestePendente(true);

    const navegarParaDashboard = () => this.routerService.toDashboard();

    const isContinuacaoTutorial = this.activatedRouter.snapshot.queryParams["tutorial"] == "true";
    this.userGuideService.setSteps(
      dashboardTutorial(
        this.translateService,
        this.tipoDispositivoService.isMobile() || this.tipoDispositivoService.isTablet(),
        isContinuacaoTutorial,
        this.flIntraJornada,
        this.exibirAbaSinalizadorProntidao,
        this.exibirAbaFadiga,
        this.roleService.isRole(RoleService.ACAO_DEFINIDA_REGEX),
        isResumoLoaded,
        isGraficosLoaded,
        changeTab,
        carregarDadosSinalizadorPrevencao,
        carregarDadosIndicadorFadiga,
        navegarAcoesPendentesMobile,
        navegarParaDashboard
      )
    );
  }

  async carregarGraficos(filtros: any) {
    this.listaGraficosExibir = LISTA_GRAFICO_DASHBOARD.filter(
      (grafico) => grafico.permissoes.find((permissao) => this.roleService.isRole(permissao)) != undefined
    );
    for (let grafico of this.listaGraficosExibir) {
      this.fetchGraficos(Util.deepClone(filtros), grafico);
    }
  }

  fetchGraficos(filtros, grafico) {
    grafico.isLoading = true;
    this.dashboardService.carregarGraficos(Util.deepClone(filtros), grafico.chave).subscribe(
      (resp) => {
        const graficoFinded = this.listaGraficosExibir.find((g) => g.chave == resp.dsChave);
        graficoFinded.isLoading = false;
        graficoFinded.dados = resp;
        grafico.hasError = false;
      },
      (err) => {
        grafico.isLoading = false;
        grafico.hasError = true;
        let dontShowGraph = false;
        if (err?.error?.find) {
          dontShowGraph = err?.error?.find((err) => err.dsErroInterno == "grafico.nao.deve.ser.exibido");
        }
        if (err.status !== 400 || !dontShowGraph) {
          throw err;
        }
      }
    );
  }

  exibirInformacoesTela() {
    this.ExibirHierarquia = !!this.dadosFiltroGraficoDashboard.idHierarquia;
  }

  carregarInformacaoUsuario(usuario: ResponseUsuarioSimplesTO) {
    this.ExibirInformacaoUnica = true;
    this.botaoGraficoAcaoPendente = true;
    this.detalheAcaoADefinirUnico = new DetalheAcaoADefinir();
    this.detalheAcaoADefinirUnico.dsUsuario = usuario.dsNome;
    this.detalheAcaoADefinirUnico.dsCargo = usuario.dsCargo;
    this.detalheAcaoADefinirUnico.dsHierarquia = [usuario.dsHierarquia];
    this.detalheAcaoADefinirUnico.cdMatricula = usuario.cdMatricula;
    this.idTesteExecutado = usuario.idUltimoTesteExecutado;
  }

  carregarDataMinima() {
    const isAdministrator = this.roleService.isRole(Permissao.ADMINISTRADOR);
    const isGestorSME = this.roleService.isRole(Permissao.GESTOR_SME);
    const isGestorSmeMedicao = this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    if (isAdministrator || isGestorSME || isGestorSmeMedicao) {
      const date = new Date();
      this.minimoDiaMes = new Date(date.getFullYear() - 3, date.getMonth(), date.getDay());
    } else {
      const date = new Date();
      this.minimoDiaMes = new Date(date.getFullYear(), date.getMonth(), -120);
    }
  }

  exibirDialogoFiltroGrafico(): void {
    if (this.tabSinalizadorProntidao?.selected) {
      this.sinalizadorProntidao.openFiltros();
    } else {
      this.filtrosDashboardComponent.showFiltros();
    }
  }

  exibirDialogoDefinirAcaoEmLote(): void {
    this.showDialogDefinirAcaoEmLote = true;
  }

  fecharDialogoFiltroAcaoPendenteAcaoDefinica(): void {
    // this.showDialogFiltroPendenteAcaoDefinida = false;
  }

  fecharDialogoFiltroAcaoPendenteAcaoDefinicaConfirmar(): void {
    // this.showDialogFiltroPendenteAcaoDefinida = false;
  }

  fecharDialogoFiltroGrafico(): void {
    this.showDialogFiltroGrafico = false;
  }

  fecharDialogoFiltroGraficoConfirmar(): void {
    this.showDialogFiltroGrafico = false;
  }

  fecharDialogoDefirAcaoEmLote(): void {
    this.showDialogDefinirAcaoEmLote = false;
  }

  abrirCargo(op: any, event): void {
    op.toggle(event);
    setTimeout((_) => {
      const containerCargo: any = document.querySelector("#cargo > div");
      const tamanhoContainerCargo: number = document.querySelector("#idContainerCargo").clientWidth;
      const top: any = document.querySelector("#idContainerCargo").getBoundingClientRect().top;
      this.render2.setStyle(containerCargo, "width", `${tamanhoContainerCargo}px`);
      this.render2.setStyle(containerCargo, "top", "");
      this.render2.setStyle(containerCargo, "overflow", "auto");
      this.render2.setStyle(containerCargo, "height", "250px");
      this.render2.setStyle(containerCargo, "top", `${top + 40}px`);
    }, 0);
  }

  cargoNodeSelect(event): void {
    this.dadosFiltroGraficoDashboard.idCargo = event.data.id;
  }

  getDadosSegurancaPsicologica() {
    this.recebeLoadButton = true;
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    this.dashboardService.getGraficoSegurancaPsicologica(filtros).subscribe(
      (r) => {
        this.recebeLoadButton = false;
        this.responseSegurancaPsicologica = r;
        this.filtrosDashboardComponent.fecharDialogoFiltroGrafico();
      },
      (err) => {
        this.recebeLoadButton = false;
        throw err;
      }
    );
  }

  handleChange() {
    this.messageService.speak(TipoMessageEnum.DASHBOARD_READONLY, false);
    this.tabFadigaSelected = false;
    if (this.tabProntidao && this.tabProntidao.selected) {
      this.dadosFiltroGraficoDashboard.flIntrajornada = SimNaoEnum.NAO;
      this.loadDadosDashboard();
      setTimeout(() => {
        this.messageService.speak(TipoMessageEnum.CARREGAR_TESTES_PENDENTES, true);
      });
    } else if (this.tabIntrajornada && this.tabIntrajornada.selected) {
      this.dadosFiltroGraficoDashboard.flIntrajornada = SimNaoEnum.SIM;
      this.loadDadosDashboard();
      setTimeout(() => {
        this.messageService.speak(TipoMessageEnum.CARREGAR_TESTES_PENDENTES, true);
      });
    } else if (this.tabSeguranca && this.tabSeguranca.selected) {
      this.getDadosSegurancaPsicologica();
    } else if (this.tabFadiga && this.tabFadiga.selected) {
      this.tabFadigaSelected = true;
      this.messageService.speak(TipoMessageEnum.DASHBOARD_READONLY, true);
      this.getTodosGraficosIndicadoresFadiga();
    } else if (this.tabSinalizadorProntidao?.selected) {
      this.messageService.speak(TipoMessageEnum.DASHBOARD_READONLY, true);
    }
    // var index = e.index;
  }

  getTodosGraficosIndicadoresFadiga() {
    this.recebeLoadButton = true;
    const filtros = new FiltroIndicadorFadiga().convertFromFiltroDashbaord(this.dadosFiltroGraficoDashboard);
    this.dashboardService.getGraficoIndicadorFadiga(filtros).subscribe(
      (r) => {
        this.recebeLoadButton = false;
        this.filtrosDashboardComponent.fecharDialogoFiltroGrafico();
        this.responseFadiga = r;
        console.log(r);
      },
      (err) => {
        this.recebeLoadButton = false;
        throw err;
      }
    );
  }

  filtrarGraficos(filtros: FiltroGraficoDashboad) {
    this.dadosFiltroGraficoDashboard = filtros;
    this.handleChange();
  }

  exibirDialogAcaoPendente(): void {
    this.showDialogAcaoPendente = true;
    this.dashboardService.carregarDadosTemporalUsuario(this.idTesteExecutado).subscribe(
      (resultado) => {
        this.detalheAcaoADefinir = resultado;
        this.detalheAcaoADefinir.dtExecucao = new Date(resultado.dtExecucao);
        this.exibirGraficoAnaliseProntidao = true;
      },
      (error) => {
        this.exibirGraficoAnaliseProntidao = false;
        throw error;
      }
    );
  }

  voltar() {
    this.listaGraficosExibir = [];
    this.loadDadosDashboard();
    this.showBotaoFiltro = true;
  }

  exibirLoaderDetalhe() {
    this.loaderDetalhe = true;
  }

  ocultarLoaderDetalhe() {
    this.loaderDetalhe = false;
  }

  configurarTabela() {
    this.colunasUsuariosPendentes = [
      {
        header: this.getTituloTraducao("usuario.nomeTitulo"),
        field: "dsNome",
        width: "30%",
      },
      {
        header: this.getTituloTraducao("usuario.hierarquia"),
        field: "dsHierarquia",
        width: "30%",
      },
      {
        header: this.getTituloTraducao("usuario.matriculaColuna"),
        field: "cdMatricula",
        textHeaderAlign: "center",
        textAlign: "center",
        width: "25%",
      },
      {
        header: this.getTituloTraducao("avaliacao.dtUltExecucao"),
        valueDisplayFn: (valor) =>
          valor?.dtUltExecucao ? this.datePipe.transform(moment(valor.dtUltExecucao).toDate(), "short") : "-",
        width: "15%",
      },
    ];
  }

  configurarTabelaUsuariosTotal() {
    this.colunasUsuariosCadastrados = [
      {
        header: this.getTituloTraducao("usuario.nomeTitulo"),
        field: "dsNome",
        width: "30%",
      },
      {
        header: this.getTituloTraducao("usuario.hierarquia"),
        field: "dsHierarquia",
        width: "30%",
      },
      {
        header: this.getTituloTraducao("usuario.matriculaColuna"),
        field: "cdMatricula",
        textHeaderAlign: "center",
        textAlign: "center",
        width: "30%",
      },
    ];
  }

  configurarTabelaAvaliacoesRealizadas() {
    this.colunasAvaliacoesRealizadas = [];
    if (this.configuracoes.flExibeIdTesteImpedimentoResultado) {
      this.colunasAvaliacoesRealizadas.push({
        header: this.getTituloTraducao("avaliacao.idTeste"),
        width: "10%",
        field: "idTesteExecutado",
        textHeaderAlign: "center",
        textAlign: "center",
      });
    }

    this.colunasAvaliacoesRealizadas.push(
      {
        header: this.getTituloTraducao("avaliacao.nome"),
        field: "dsNome",
        width: "30%",
        showTooltip: (obj) => {
          return obj?.qtRealizadoEscala > 0
            ? this.translateService.instant("avaliacao.realizouEscalaFolga")
            : "";
        },
      },
      {
        header: this.getTituloTraducao("usuario.hierarquia"),
        field: "dsHierarquia",
        width: "25%",
      },
      {
        header: this.getTituloTraducao("avaliacao.nivelResultado"),
        valueDisplayFn: (valor) => valor.dsNivelResultado,
        textHeaderAlign: "center",
        textAlign: "center",
      },
      {
        header: this.getTituloTraducao("avaliacao.dtUltExecucao"),
        width: "15%",
        valueDisplayFn: (valor) =>
          valor?.dtUltExecucao ? this.datePipe.transform(moment(valor.dtUltExecucao).toDate(), "short") : "-",
      },
      {
        header: this.getTituloTraducao("avaliacao.qtRealizado"),
        field: "qtRealizado",
      }
    );
  }

  getTituloTraducao(traducao: string): string {
    return this.translateService.instant(traducao);
  }

  adicionarColunaCatraca(response: ResponseResumoUsuarioTO[], colunas: any) {
    const possuiDhUltEntradaCatraca = response.some((item) => item.dhUltEntradaCatraca);
    const colunaAdicionada = colunas.find((c) => c.id == "coluna-catraca");
    if (possuiDhUltEntradaCatraca && !colunaAdicionada) {
      colunas.push({
        id: "coluna-catraca",
        header: this.getTituloTraducao("avaliacao.dhUltEntradaCatraca"),
        valueDisplayFn: (valor) =>
          valor?.dhUltEntradaCatraca
            ? this.datePipe.transform(moment(valor.dhUltEntradaCatraca).toDate(), "short")
            : "-",
        width: "15%",
      });
    }
  }

  atualizarSourceObservableUsuariosCadastrados(pagina?: number) {
    this.nrPaginaCadastrados = pagina || 1;
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    this.exibirInformacoesTela();

    this.sourceObservableUsuariosCadastrados = this.dashboardService
      .listarUsuariosCadastrados(
        this.nrPaginaCadastrados,
        30,
        filtros.idHierarquia,
        filtros.flIncluirFilhas,
        filtros.idCargo,
        filtros.idTurno,
        filtros.situacaoImpulsividadeEstimada,
        filtros.dtInicio,
        filtros.dtFim,
        filtros.flIntrajornada,
        filtros.flApenasUsuarioAtivos,
        filtros.cdMatriculaUsuario,
        filtros.flIncluirAvisoAusencia,
        filtros.dsFormacao
      )
      .pipe(
        tap((response: any) => this.adicionarColunaCatraca(response.dados, this.colunasUsuariosCadastrados))
      );
  }

  atualizarSourceObservableAvaliacoesRealizadas(pagina?: number) {
    this.nrPaginaRealizadas = pagina || 1;
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    this.exibirInformacoesTela();

    this.sourceObservableAvaliacoesRealizadas = this.dashboardService
      .listarAvaliacoesRealizadas(
        this.nrPaginaRealizadas,
        30,
        filtros.idHierarquia,
        filtros.flIncluirFilhas,
        filtros.idCargo,
        filtros.idTurno,
        filtros.dtInicio,
        filtros.dtFim,
        filtros.situacaoImpulsividadeEstimada,
        filtros.flIntrajornada,
        filtros.flApenasUsuarioAtivos,
        filtros.cdMatriculaUsuario,
        filtros.flIncluirAvisoAusencia,
        filtros.dsFormacao
      )
      .pipe(
        tap((response: any) => this.adicionarColunaCatraca(response.dados, this.colunasAvaliacoesRealizadas))
      );
  }

  atualizarSourceObservable(pagina?: number) {
    this.nrPaginaPendentes = pagina || 1;
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    this.exibirInformacoesTela();

    this.sourceObservable = this.dashboardService
      .listarUsuariosPendentes(
        this.nrPaginaPendentes,
        30,
        filtros.idHierarquia,
        filtros.flIncluirFilhas,
        filtros.idCargo,
        filtros.idTurno,
        filtros.dtInicio,
        filtros.dtFim,
        filtros.situacaoImpulsividadeEstimada,
        filtros.flIntrajornada,
        filtros.flApenasUsuarioAtivos,
        filtros.cdMatriculaUsuario,
        filtros.flIncluirAvisoAusencia,
        filtros.dsFormacao
      )
      .pipe(
        tap((response: any) => this.adicionarColunaCatraca(response.dados, this.colunasUsuariosPendentes))
      );
  }

  atualizarSourceObservableGraficoQueixa(pagina?: number) {
    this.nrPaginaGraficoBemEstar = pagina || 1;
    const filtros = this.dadosFiltroGraficoDashboard.toApi();
    // this.exibirInformacoesTela();
    this.loading = true;
    this.listaGraficoBemEstar = null;
    this.dashboardService.listarGraficoBemEstar(this.nrPaginaGraficoBemEstar, 30, filtros).subscribe(
      (r) => {
        this.loading = false;
        this.listaGraficoBemEstar = r;
      },
      (err) => {
        this.loading = false;
        throw err;
      }
    );
  }

  recebeIdPergunta(event, obj: any) {
    const isAdministrator = this.roleService.isRole(Permissao.ADMINISTRADOR);
    const isGestorSME = this.roleService.isRole(Permissao.GESTOR_SME);
    const isGestorSMEMedicao = this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    const isMedico = this.roleService.isRole(Permissao.MEDICO);
    const isGestor = this.roleService.isRole(Permissao.GESTOR);
    const isBemEstar = obj?.dsChave === "QUEIXAS";
    const isEstatiscaAcoes = obj?.dsChave === "ESTATISTICA_ACOES";
    const isTermometroHumor = obj?.dsChave === "EMOCIOGRAMA";
    const isGestaoAcoes = obj?.dsChave === "GESTAO_ACOES";
    const isGestaoSaude = obj?.dsChave === "GESTAO_SAUDE";
    const handleResumo = () =>
      this.getResumo(event.id, `${obj.dsTitulo}: ${event.name}`, obj?.dsChave, event.idStr);

    if (isTermometroHumor && ClienteUtil.isCaraiba()) {
      return;
    }

    if (isBemEstar) {
      if (isAdministrator || isGestorSME || isMedico || isGestorSMEMedicao) {
        handleResumo();
      }
    } else if (
      isEstatiscaAcoes ||
      isGestaoAcoes ||
      isGestaoSaude ||
      !isGestor ||
      (isTermometroHumor && isGestor)
    ) {
      handleResumo();
    }
  }

  getResumo(id: number, titulo: string, chave: string, idStr: string) {
    const data = {
      ESTATISTICA_ACOES: this.dashboardService.getResumoEstatiscasAcoes.bind(this.dashboardService),
      RESULTADO_AVALIACAO: this.dashboardService.getResumoResultadoAvaliacao.bind(this.dashboardService),
      EMOCIOGRAMA: this.dashboardService.getResumoEmociograma.bind(this.dashboardService),
      PARAMETROS_ANALISE: this.dashboardService.getResumoParametrosAnalise.bind(this.dashboardService),
      GESTAO_SAUDE: (filtros) => {
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltTeste";
        return this.dashboardService.getResumoGestaoSaude(filtros);
      },
      GESTAO_ACOES: (filtros) => {
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltTeste";
        return this.dashboardService.getResumoGestaoAcoes(filtros);
      },
      QUEIXAS: (filtros) => {
        this.dadosFiltroGraficoDashboard.id = id;
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltExecucao";
        return this.dashboardService.listarGraficoBemEstar(1, 30, filtros);
      },
    };
    if (!data[chave]) {
      return;
    }
    const filtros = this.dadosFiltroGraficoDashboard.toApi(id, idStr);
    this.exibirResumoPopup = true;
    this.tituloResumo = titulo;
    this.loading = true;
    this.listaPopupResumo = [];
    this.showColumaDataPopupResumo = false;
    this.labelDataUltimoTeste = "graficoBemEstar.dtUltExecucao";
    data[chave](filtros).subscribe(
      (response) => {
        this.loading = false;
        this.listaPopupResumo = response;
      },
      (err) => {
        this.loading = false;
        throw err;
      }
    );
  }

  loadConfigJornada() {
    this.flIntraJornada = false;
    if (this.configuracoes.qtMinutosTesteIntrajornada > 0) {
      this.flIntraJornada = true;
    }
  }

  exibirInformacoesTop() {
    return this.ExibirInformacaoUnica || this.ExibirHierarquia;
  }

  imprimirPagina() {
    if (!this.tipoDispositivoService.isFirefox()) {
      window.print();
      return;
    }
    const body = document.body;
    body.classList.add("print");
    html2canvas(body).then(async (canvas) => {
      setTimeout(async () => {
        const blob: any = await new Promise((resolve) => canvas.toBlob(resolve));
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        body.classList.remove("print");
      }, 1000);
    });
  }
}
