import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "../models/select-item.model";
import { Util } from "../services/util.service";
export enum TipoJustificativaAusenciaEnum {
  OUTROS = "OUTROS",
  FERIAS = "FERIAS",
  ATESTADO = "ATESTADO",
  AUSENCIA_JUSTIFICADA = "AUSENCIA_JUSTIFICADA",
  COMPENSACAO_HORAS = "COMPENSACAO_HORAS",
  FALTA = "FALTA",
  INTEGRACAO = "INTEGRACAO",
}

export const getTipoJustificativasAusencia = (translateService: TranslateService): SelectItem[] => {
  const justificativas: SelectItem[] = [
    {
      label: translateService.instant("avisoFerias.ferias"),
      value: TipoJustificativaAusenciaEnum.FERIAS,
    },
    {
      label: translateService.instant("avisoFerias.ausenciaJustificada"),
      value: TipoJustificativaAusenciaEnum.AUSENCIA_JUSTIFICADA,
    },
    {
      label: translateService.instant("avisoFerias.falta"),
      value: TipoJustificativaAusenciaEnum.FALTA,
    },
    {
      label: translateService.instant("avisoFerias.atestado"),
      value: TipoJustificativaAusenciaEnum.ATESTADO,
    },
    {
      label: translateService.instant("avisoFerias.compensacaoHoras"),
      value: TipoJustificativaAusenciaEnum.COMPENSACAO_HORAS,
    },
    {
      label: translateService.instant("avisoFerias.integracao"),
      value: TipoJustificativaAusenciaEnum.INTEGRACAO,
    },
  ];

  Util.ordenarAlfabetica(justificativas, "label");
  justificativas.push({
    label: translateService.instant("avisoFerias.outros"),
    value: TipoJustificativaAusenciaEnum.OUTROS,
  });
  return justificativas;
};
