import { TranslateService } from "@ngx-translate/core";
import { UserGuideStep } from "src/app/shared/models/user-guide-step.model";
import { Util } from "src/app/shared/services/util.service";
import { testesPendentesListTutorial } from "../testes/components/testes-pendentes-list/testes-pendentes-list.tutorial";

const findIndexTab = (element: string) => {
  const itens = [...(document.querySelectorAll("#dashboard-tab .p-tabview-nav-link") as any)];
  const itemFinded = itens.find((item) => item.querySelector(element));
  return itemFinded ? itemFinded.getAttribute("data-pc-index") : null;
};

const waitElementOrJump = async (element: string, step) => {
  const elementLoaded = await Util.waitUntil(() => !!document.querySelector(element), 1000);
  if (elementLoaded) {
    return true;
  } else {
    step.next();
    throw element + " Pular etapa";
  }
};

export const dashboardTutorial = (
  translateService: TranslateService,
  isMobile: boolean,
  isContinuacaoTutorial,
  showAbaIntrajornada: boolean,
  showAbaSinalizadorPrevencao: boolean,
  showAbaFadiga: boolean,
  exibirAcoesPendentes: boolean,
  isResumoLoaded: () => boolean,
  isGraficosLoaded: () => boolean,
  changeTab: (index: number) => void,
  carregarDadosSinalizadorPrevencao: () => Promise<boolean>,
  exibirTabelaIndicadorFadiga: () => void,
  navegarAcoesPendentesMobile: () => void,
  navegarParaDashboard: () => void
): UserGuideStep[] => {
  const parte1: UserGuideStep[] = [
    {
      title: "tutorial.dashboard.tituloTourGuiado",
      text:
        `<img src="assets/images/tutorial/saudacao-dashboard.png" class="p-d-block p-mx-auto" style="width: 90%;max-width: 256px;margin-bottom: 24px;"/>` +
        translateService.instant(`tutorial.dashboard.bemVindo`),
      element: undefined,
      backButtonText: "tutorial.lembrarMaisTarde",
      nextButtonText: "tutorial.iniciarTourGuiado",
      translateText: false,
      customClass: "bigger",
    },
    {
      title: "tutorial.dashboard.titulo",
      text: `tutorial.dashboard.sidebar`,
      element: `[data-tutorial='${isMobile ? "menu-dashboard-mobile" : "menu-dashboard"}']`,
      position: "bottom",
    },
    {
      title: "tutorial.dashboard.tituloAbaAvaliacoesProntidao",
      text: `tutorial.dashboard.container`,
      element: "[data-tutorial='dashboard-container']",
      position: "top",
      beforeShowPromise: () =>
        new Promise((res) => {
          changeTab(0);
          res(true);
        }),
    },
    {
      title: "tutorial.dashboard.tituloCardUsuariosEmAtividade",
      text: `tutorial.dashboard.usuarioAtividade`,
      element: "[data-tutorial='usuario-atividade']",
      position: "right",
      beforeShowPromise: function () {
        let self = this;
        return new Promise(async (res, rej) => {
          const resumoLoaded = await Util.waitUntil(isResumoLoaded, 10000);
          if (resumoLoaded) {
            res(true);
          } else {
            self.next();
            rej("[data-tutorial='usuario-atividade'] Pular etapa");
          }
        });
      },
    },
    {
      title: "tutorial.dashboard.tituloCardAvaliacoesRealizadas",
      text: `tutorial.dashboard.avaliacoesRealizadas`,
      element: "[data-tutorial='avaliacoes-realizadas']",
      position: "right",
    },
    {
      title: "tutorial.dashboard.tituloCardUsuariosSemAvailacao",
      text: `tutorial.dashboard.usuariosSemAvaliacoes`,
      element: "[data-tutorial='usuarios-sem-avaliacoes']",
      position: "right",
    },
    {
      title: "tutorial.dashboard.tituloAvaliacoesProntidao",
      text: `tutorial.dashboard.graficoDashboard`,
      element: "[data-tutorial='grafico-dashboard']",
      position: "right",
      beforeShowPromise: function () {
        let self = this;
        return new Promise(async (res, rej) => {
          changeTab(0);
          const graficosLoaded = await Util.waitUntil(isGraficosLoaded, 15000);
          if (graficosLoaded) {
            res(true);
          } else {
            self.next();
            rej("[data-tutorial='grafico-dashboard'] Pular etapa");
          }
        });
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoResultadoAvaliacao",
      text: `tutorial.dashboard.graficoResultadoAvaliacao`,
      element: "[data-tutorial='grafico-RESULTADO_AVALIACAO']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-RESULTADO_AVALIACAO']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoAdesao",
      text: `tutorial.dashboard.graficoAdesao`,
      element: "[data-tutorial='grafico-ADESAO']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-ADESAO']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoParametrosAnalise",
      text: `tutorial.dashboard.graficoParametrosAnalise`,
      element: "[data-tutorial='grafico-PARAMETROS_ANALISE']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-PARAMETROS_ANALISE']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoQueixas",
      text: `tutorial.dashboard.graficoQueixas`,
      element: "[data-tutorial='grafico-QUEIXAS']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-QUEIXAS']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoEstatisticaAcoes",
      text: `tutorial.dashboard.graficoEstatisticasAcoes`,
      element: "[data-tutorial='grafico-ESTATISTICA_ACOES']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-ESTATISTICA_ACOES']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoGestaoAcoes",
      text: `tutorial.dashboard.graficoGestaoAcoes`,
      element: "[data-tutorial='grafico-GESTAO_ACOES']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-GESTAO_ACOES']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoAcoesDefinidas",
      text: `tutorial.dashboard.graficoAcoesDefinidas`,
      element: "[data-tutorial='grafico-ACOES_DEFINIDAS']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-ACOES_DEFINIDAS']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoEmociograma",
      text: `tutorial.dashboard.graficoEmociograma`,
      element: "[data-tutorial='grafico-EMOCIOGRAMA']",
      position: "right",
      beforeShowPromise: function () {
        return waitElementOrJump("[data-tutorial='grafico-EMOCIOGRAMA']", this);
      },
    },
    {
      title: "tutorial.dashboard.tituloGraficoGestaoSaude",
      text: `tutorial.dashboard.graficoGestaoSaude`,
      element: "[data-tutorial='grafico-GESTAO_SAUDE']",
      position: "right",
      nextButtonText: "paginacao.proximo",
      beforeShowPromise: async function () {
        const element = "[data-tutorial='grafico-GESTAO_SAUDE']";
        const hasGestaoSaude = await Util.waitUntil(() => !!document.querySelector(element), 0);
        if (hasGestaoSaude) return true;
        if (isMobile && exibirAcoesPendentes) {
          navegarAcoesPendentesMobile();
        } else {
          this.next();
        }
        throw element + " Pular etapa";
      },
      stopAfterHide: isMobile && exibirAcoesPendentes,
      whenHide: function () {
        if (isMobile && exibirAcoesPendentes) {
          navegarAcoesPendentesMobile();
        }
      },
    },
  ];

  const parte2: UserGuideStep[] = [
    {
      title: "tutorial.dashboard.tituloAbaIntrajornada",
      text: `tutorial.dashboard.abaIntrajornada`,
      element: "[data-tutorial='aba-intrajornada']",
      position: "top",
      backButtonText: "paginacao.anterior",
      nextButtonText: "paginacao.proximo",
      customClass: "bigger",
      beforeShowPromise: () =>
        new Promise((res) => {
          const itemIndex = findIndexTab("[data-tutorial='aba-intrajornada']");
          if (itemIndex) {
            changeTab(Number(itemIndex));
          }
          res(true);
        }),
      showOn: function () {
        return showAbaIntrajornada;
      },
    },
    {
      title: "tutorial.dashboard.tituloAbaSinalizadorPrevencao",
      text: `tutorial.dashboard.abaSinalizadorPrevencao`,
      element: "[data-tutorial='aba-sinalizador-prevencao']",
      position: "top",
      customClass: "bigger",
      beforeShowPromise: function () {
        let self = this;
        return new Promise(async (res) => {
          await waitElementOrJump("[data-tutorial='aba-sinalizador-prevencao']", self);
          const itemIndex = findIndexTab("[data-tutorial='aba-sinalizador-prevencao']");
          if (itemIndex) {
            changeTab(Number(itemIndex));
          }
          res(true);
        });
      },
      showOn: function () {
        return showAbaSinalizadorPrevencao;
      },
    },
    {
      title: "tutorial.dashboard.tituloScoreTotal",
      text: `tutorial.dashboard.scoreTotal`,
      element: "[data-tutorial='coluna-score-total']",
      position: "top",
      beforeShowPromise: function () {
        const self = this;
        return new Promise(async (res, rej) => {
          const telaCarregada = await carregarDadosSinalizadorPrevencao();
          const onScreen = await Util.waitUntil(
            () => !!document.querySelector('[data-tutorial="coluna-score-total"]')
          );
          if (!telaCarregada || !onScreen) {
            self.next();
            rej("[data-tutorial='coluna-score-total'] Pular Step");
          } else {
            document.querySelector('[data-tutorial="coluna-score-total"]').scrollIntoView();
            setTimeout(() => {
              res(true);
            });
          }
        });
      },
      showOn: function () {
        return showAbaSinalizadorPrevencao;
      },
    },
    {
      title: "tutorial.dashboard.tituloAbaIndicadorFadiga",
      text: `tutorial.dashboard.abaIndicadorFadiga`,
      element: "[data-tutorial='aba-indicador-fadiga']",
      position: "top",
      customClass: "bigger",
      beforeShowPromise: () =>
        new Promise((res) => {
          const itemIndex = findIndexTab("[data-tutorial='aba-indicador-fadiga']");
          if (itemIndex) {
            changeTab(Number(itemIndex));
          }
          res(true);
        }),
      showOn: function () {
        return showAbaFadiga;
      },
    },
    {
      title: "tutorial.dashboard.tituloAbaIndicadorFadiga",
      text: `tutorial.dashboard.indiciosFadigaDiario`,
      element: "[data-tutorial='card-indicador-diario']",
      position: "right",
      beforeShowPromise: function () {
        let self = this;
        return new Promise(async (res, rej) => {
          const hasCardDiario = await Util.waitUntil(
            () => !!document.querySelector("[data-tutorial='card-indicador-diario']")
          );
          if (hasCardDiario) {
            exibirTabelaIndicadorFadiga();
            res(true);
          } else {
            self.next();
            rej("[card-indicador-diario] Pular Step");
          }
        });
      },
      showOn: function () {
        return showAbaFadiga;
      },
    },
    {
      title: "tutorial.dashboard.tituloAbaIndicadorFadiga",
      text: `tutorial.dashboard.tabelaIndiciosFadigaDiario`,
      element: "[data-tutorial='tabela-indicador-fadiga']",
      position: "top",
      beforeShowPromise: function () {
        let self = this;
        return new Promise(async (res, rej) => {
          const hasTabelaDiario = await Util.waitUntil(
            () => !!document.querySelector("[data-tutorial='tabela-indicador-fadiga']")
          );
          if (hasTabelaDiario) {
            res(true);
          } else {
            self.next();
            rej("[data-tutorial='tabela-indicador-fadiga'] Pular Step");
          }
        });
      },
      showOn: function () {
        return showAbaFadiga;
      },
    },
    {
      title: "tutorial.dashboard.tituloTourGuiado",
      text: `tutorial.dashboard.repetirTutorial`,
      element: "[data-tutorial='btn-repetir-tutorial']",
      position: "top",
      beforeShowPromise: () =>
        new Promise((res) => {
          changeTab(0);
          res(true);
        }),
    },
    {
      title: "tutorial.dashboard.tituloTourGuiado",
      text:
        `<img src="assets/images/tutorial/finalizar-dashboard.png" class="p-d-block p-mx-auto" style="width: 90%;max-width: 256px;margin-bottom: 24px;"/>` +
        translateService.instant(`tutorial.dashboard.textoFinalizar`),
      element: undefined,
      translateText: false,
      customClass: "bigger",
      whenHide: () => {
        navegarParaDashboard();
      },
    },
  ];

  const acoesPendentesTutorial = exibirAcoesPendentes ? testesPendentesListTutorial(isMobile, null) : [];
  if (!isMobile) {
    return [...parte1, ...acoesPendentesTutorial, ...parte2];
  } else {
    if (exibirAcoesPendentes) {
      return isContinuacaoTutorial ? parte2 : parte1;
    } else {
      return [...parte1, ...parte2];
    }
  }
};
