import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { SinalizadorProntidaoService } from "src/app/shared/api-services/sinalizador-prontidao.service";
import { ParamsSinalizadorProntidaoUsuariosHierarquia } from "src/app/shared/tos/request/params-sinalizador-prontidao-usuarios-hierarquia";

@Component({
  selector: "app-download-sinalizador-prontidao",
  templateUrl: "./download-sinalizador-prontidao.component.html",
  styleUrls: ["./download-sinalizador-prontidao.component.scss"],
})
export class DownloadSinalizadorProntidaoComponent {
  @Input() filtros: ParamsSinalizadorProntidaoUsuariosHierarquia;
  loading: boolean = false;

  constructor(
    private sinalizadorProntidaoService: SinalizadorProntidaoService,
    private translateService: TranslateService
  ) {}

  downloadSinalizadorProntidao() {
    this.loading = true;
    this.sinalizadorProntidaoService.downloadSinalizadorProntidaoService(this.filtros).subscribe(
      (file) => {
        this.loading = false;
        saveAs(file, this.translateService.instant("sinalizador.tituloAba") + ".zip");
      },
      (err) => {
        this.loading = false;
        throw err;
      }
    );
  }
}
