<div class="p-grid p-mb-3">
  @if (graficoDiario) {
  <div class="p-col-12 p-md-4" data-tutorial="card-indicador-diario">
    <app-indicador-fadiga-card
      [pTooltip]="'indicadorFadiga.hintDiario' | translate"
      [escape]="false"
      [titulo]="'indicadorFadiga.diario' | translate"
      [totalTestes]="graficoDiario.qtdTotalTeste"
      [nivelFadiga]="getFadiga(graficoDiario.vlModaMediana)"
      [selected]="isTermometroSelecionado('DIARIO')"
      (click)="handleGraficoSelecionado('DIARIO')"
    >
    </app-indicador-fadiga-card>
  </div>
  } @if (graficoInterjornada) {
  <div class="p-col-12 p-md-4">
    <app-indicador-fadiga-card
      [pTooltip]="'indicadorFadiga.hintInterjornada' | translate"
      [escape]="false"
      [titulo]="'indicadorFadiga.interjornada' | translate"
      [totalTestes]="graficoInterjornada.qtdTotalTeste"
      [nivelFadiga]="getFadiga(graficoInterjornada.vlModaMediana)"
      [selected]="isTermometroSelecionado('INTERJORNADA')"
      (click)="handleGraficoSelecionado('INTERJORNADA')"
    >
    </app-indicador-fadiga-card>
  </div>
  } @if (graficoIntrajornada) {
  <div class="p-col-12 p-md-4">
    <app-indicador-fadiga-card
      [pTooltip]="'indicadorFadiga.hintIntrajornada' | translate"
      [escape]="false"
      [titulo]="'indicadorFadiga.intrajornada' | translate"
      [totalTestes]="graficoIntrajornada.qtdTotalTeste"
      [nivelFadiga]="getFadiga(graficoIntrajornada.vlModaMediana)"
      [selected]="isTermometroSelecionado('INTRAJORNADA')"
      (click)="handleGraficoSelecionado('INTRAJORNADA')"
    >
    </app-indicador-fadiga-card>
  </div>
  } @if (!graficoDiario && !graficoInterjornada && !graficoIntrajornada) {
  <div class="p-col-12 sem-dados" translate>indicadorFadiga.semDados</div>
  }
</div>

@if (graficoSelecionado) {
<div class="p-grid p-md-0">
  <div class="p-col-12" data-tutorial="tabela-indicador-fadiga">
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <em class="material-icons">table_chart</em>
          <span class="title"> {{ tituloTabela }}</span>
        </div>
        <div class="card-pagination">
          <app-paginacao
            (pageChange)="handlePageChange($event)"
            [page]="paginaUsuarios"
            [pageSize]="qtdItensPagina"
            [total]="qtdTotal"
          >
          </app-paginacao>
        </div>
      </div>
      <div class="card-body">
        <div class="p-grid" style="min-height: 300px">
          <app-loader [loading]="loadingUsuarios" />

          @if (!loadingUsuarios && !dadosIndicadorFadiga?.length) {
          <div class="p-col-12 sem-dados" translate>indicadorFadiga.semDados</div>
          } @if (!loadingUsuarios && dadosIndicadorFadiga?.length) {
          <div class="p-col-12 p-md-4">
            <ng-container *ngTemplateOutlet="tabelaScore; context: { $implicit: 1 }"></ng-container>
          </div>
          <div class="p-col-12 p-md-4">
            <ng-container *ngTemplateOutlet="tabelaScore; context: { $implicit: 2 }"></ng-container>
          </div>
          <div class="p-col-12 p-md-4">
            <ng-container *ngTemplateOutlet="tabelaScore; context: { $implicit: 3 }"></ng-container>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
}

<app-grafico-indicador-fadiga
  [idUsuario]="usuarioSelecionado?.idUsuario"
  [username]="usuarioSelecionado?.dsNomeUsuario"
/>

<ng-template #tabelaScore let-nrTabela>
  <table class="tabela-score">
    <thead>
      <tr>
        <th translate>indicadorFadiga.usuarioScore</th>
        <th translate>indicadorFadiga.atual</th>
        <th translate>indicadorFadiga.anterior</th>
        <th translate [pTooltip]="'indicadorFadiga.hintHorasComparacao' | translate">
          indicadorFadiga.horasComparacao
        </th>
      </tr>
    </thead>
    <tbody>
      @for ( usuario of dadosIndicadorFadiga | slice : getInicioTabela(nrTabela) : getFimTabela(nrTabela) ;
      track usuario) {
      <tr (click)="handleUsuarioSelecionado(usuario)" [ngClass]="{ selected: isUsuarioSelected(usuario) }">
        <td
          [pTooltip]="
            isAnaliseLongoPeriodo(usuario)
              ? ('indicadorFadiga.hintLongoPeriodo' | translate)
              : ('indicadorFadiga.hintRotina' | translate)
          "
        >
          {{ usuario.dsNomeUsuario }}
          @if (isAnaliseLongoPeriodo(usuario)) {
          <span> * </span>
          }
        </td>
        <td>
          <div class="bolinha-nivel-wrapper" translate>
            {{ getFadiga(usuario.vlScoreFadigaAtual).texto }}
            <div
              class="bolinha-nivel"
              [ngStyle]="{ background: getFadiga(usuario.vlScoreFadigaAtual).cor }"
            ></div>
          </div>
        </td>
        <td>
          <div class="bolinha-nivel-wrapper" translate>
            {{ getFadiga(usuario.vlScoreFadigaAnterior).texto }}
            <div
              class="bolinha-nivel"
              [ngStyle]="{ background: getFadiga(usuario.vlScoreFadigaAnterior).cor }"
            ></div>
          </div>
        </td>
        <td>
          {{ usuario.qtHorasComparacaoFadiga }}
        </td>
      </tr>
      }
    </tbody>
  </table>
</ng-template>
