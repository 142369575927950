import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-resumo-card',
  templateUrl: './resumo-card.component.html',
  styleUrls: ['./resumo-card.component.scss']
})
export class ResumoCardComponent implements OnInit {
  @ContentChild('badgeContent') badgeContent: TemplateRef<any>
  @Input() title: string;
  @Input() value: string;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
