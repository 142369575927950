<div class="chart-container">
  <div class="yAxis">
    <em class="pi pi-plus"></em>
    <div class="title" translate> segurancaoPsicologica.segurancaPsicologica </div>
    <em class="pi pi-minus"></em>
  </div>
  <div class="xAxis">
    <em class="pi pi-minus"></em>
    <div class="title" translate> segurancaoPsicologica.nivelResponsabilidade </div>
    <em class="pi pi-plus"></em>
  </div>
  <div class="quadrante __superior __esquerdo"> {{ qtdConforto }} {{ 'segurancaoPsicologica.conforto' | translate }} </div>
  <div class="quadrante __superior __direito"> {{ qtdAprendizagem }} {{ 'segurancaoPsicologica.aprendizagem' | translate }} </div>
  <div class="quadrante __inferior __esquerdo"> {{ qtdApatia }} {{ 'segurancaoPsicologica.apatia' | translate }} </div>
  <div class="quadrante __inferior __direito"> {{ qtdAnsiedade }} {{ 'segurancaoPsicologica.ansiedade' | translate }} </div>
  <div [chart]="chart"></div>
</div>
