<div class="card">
  <div class="p-grid">
    @if (isIdContratoRequired) {
    <div class="p-col-12 p-md-4 p-mb-3">
      <label for="idContrato" class="label-input" translate> sinalizador.idContrato </label>
      <app-dropdown
        [(ngModel)]="idContrato"
        name="idContrato"
        appendTo="body"
        [options]="listaContratos"
        [required]="isIdContratoRequired"
        (onChange)="handleOnContratoChange($event)"
      >
      </app-dropdown>
    </div>
    }
    <div class="p-col-12 p-md-4 p-mb-3" [ngClass]="isIdContratoRequired ? 'p-md-8' : ''">
      @if (showMediaGeral && media) {
      <app-media-sinalizador-prontidao [media]="media" [loading]="loading"> </app-media-sinalizador-prontidao>
      }
    </div>
  </div>

  @if (showTable()) {
  <div class="header-container">
    <app-download-sinalizador-prontidao [filtros]="filtros" />
    <app-legenda-sinalizador-prontidao />
  </div>
  } @if (showTable()) {
  <p-tabView (onChange)="handleOnTabChange($event)" [activeIndex]="idxTabAtivo">
    <p-tabPanel [header]="'sinalizador.hierarquias' | translate">
      <div class="content">
        @if (idxTabAtivo == 0) {
        <app-tabela-sinalizador-prontidao
          dataKey="idHierarquia"
          groupRowsBy="idHierarquia"
          [showNumeroTabela]="showNumeroTabela"
          [customErroTabela]="customErroTabela"
          [tituloColunaNome]="'sinalizador.hierarquias' | translate"
          [(dados)]="dadosTabela"
          [loading]="loading"
          [useHierarquiaSort]="true"
          [dynamicTableRows]="dynamicTableRows"
          [configuracao]="configuracao"
          [podeExpandirHierarquias]="podeExpandirHierarquias"
          (expandedRowClicked)="getUsuariosByHierarquia($event)"
        />
        }
      </div>
    </p-tabPanel>
    @if (showAbaUsuarios) {
    <p-tabPanel [header]="'sinalizador.usuarios' | translate">
      <div class="content">
        @if (idxTabAtivo == 1) {
        <app-tabela-sinalizador-prontidao
          dataKey="idUsuario"
          [showNumeroTabela]="showNumeroTabela"
          [showColunaHierarquia]="true"
          [customErroTabela]="customErroTabela"
          [tituloColunaNome]="'sinalizador.usuarios' | translate"
          [dados]="dadosTabela"
          [loading]="loading"
          [configuracao]="configuracao"
        />
        }
      </div>
    </p-tabPanel>
    }
  </p-tabView>
  }
</div>

<app-filtros-sinalizador-prontidao [filtrosIniciais]="filtros" (filtrar)="onFiltrar($event)" />
