import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GraficoDashboard } from 'src/app/shared/models/grafico-dashboard.model';
import { CardChartDashboardComponent } from '../card-chart-dashboard/card-chart-dashboard.component';

@Component({
  selector: 'app-card-chart-container',
  templateUrl: './card-chart-container.component.html',
  styleUrls: ['./card-chart-container.component.scss']
})
export class CardChartContainerComponent implements OnInit {
  @ViewChild('cardChartExpandido') cardChartExpandido: CardChartDashboardComponent;
  @Input() data: GraficoDashboard;
  @Input() opcoesFiltro: any[];
  @Input() type: string;
  @Input() isFilter: boolean;
  @Output() onSelectItemFiltro: EventEmitter<any> = new EventEmitter<any>();
  @Output() atualizarGraficos = new EventEmitter();
  @Output() valorId = new EventEmitter();

  showModal = false;

  constructor() { }

  ngOnInit(): void {
  }

  expandirGrafico() {
    this.showModal = true;
    setTimeout(() => {
      this.cardChartExpandido.chart.ref.reflow()
    });
  }

}
