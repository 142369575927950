<app-historico-ferias #historicoFerias (editar)="editarAviso($event)" />

<form #requiredForm="ngForm" (keydown.enter)="$event.preventDefault()">
  @if (!isAvisoFeriasVisible) {
  <div>
    <app-loader [loading]="load" />

    @if (!load) {
    <div class="conteudoPageCrud">
      <app-titulo-edicao-crud
        [titulo]="titulo"
        [hint]="'avisoFerias.hintTitulo' | translate"
      ></app-titulo-edicao-crud>
      <div class="p-grid">
        <div class="p-col-3 p-md-2" style="padding-bottom: 10px; display: flex; flex-direction: column">
          <label for="horaInicial" class="label-input" translate>avisoFerias.periodoInicial</label>
          <p-calendar
            class="calendar"
            place
            [monthNavigator]="true"
            dateFormat="dd/mm/yy"
            [locale]="getCalendarTranslate()"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [showTime]="false"
            name="dtInicioFerias"
            [disabled]="avisoEdicao"
            [(ngModel)]="dadosFiltro.dtInicioFerias"
            required
          ></p-calendar>
        </div>
        <div class="p-col-3 p-md-2" style="padding-bottom: 10px; display: flex; flex-direction: column">
          <label class="label-input" translate>avisoFerias.periodoFinal</label>
          <p-calendar
            class="calendar"
            place
            [monthNavigator]="true"
            dateFormat="dd/mm/yy"
            [locale]="getCalendarTranslate()"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [showTime]="false"
            name="dtFimFerias"
            [(ngModel)]="dadosFiltro.dtFimFerias"
            required
          ></p-calendar>
        </div>
      </div>
      <div class="p-grid colunas-wrapper">
        <div class="p-col">
          <div class="p-grid">
            <div class="p-col-6">
              <label class="label-input" translate>avisoFerias.usuario</label>
              <p-autoComplete
                [(ngModel)]="usarioSelecionado"
                name="usuariosSelecionados"
                [suggestions]="usuarios"
                field="dsNome"
                [disabled]="isUsuarioDisabled()"
                (onSelect)="appendListaUsuario($event)"
                (completeMethod)="pesquisarUsuarios($event)"
              />
            </div>
            <div class="p-col-6">
              <label class="label-input" translate>avisoFerias.matricula</label>
              <p-autoComplete
                [(ngModel)]="usarioSelecionadoByMatricula"
                name="matriculaSelecionada"
                [suggestions]="usuarios"
                field="cdMatricula"
                [disabled]="isUsuarioDisabled()"
                (onSelect)="appendListaUsuario($event)"
                (completeMethod)="pesquisarUsuariosByMatricula($event)"
              />
            </div>
            @if (isUsuarioDisabled()) {
            <small class="aviso-disabled p-col-12 p-pt-0" translate>
              avisoFerias.avisoHabilitarUsuarios
            </small>
            }
            <div class="p-col-12">
              <app-lista-itens-selecionados
                bindLabel="dsNome"
                [disabled]="avisoEdicao"
                [itens]="usuariosSelecionados"
                (removeItem)="removeUsuario($event)"
              >
                <ng-template #btnComplementar let-item>
                  @if (!disabled) {
                  <button
                    class="btn-visualizar-historico"
                    (click)="visualizarHistorico(item)"
                    [title]="'avisoFerias.historicoAviso' | translate"
                  >
                    <em class="material-icons">receipt_long</em>
                  </button>
                  }
                </ng-template>
              </app-lista-itens-selecionados>
            </div>
          </div>
        </div>
        <div class="p-col coluna-divisoria" translate>ou</div>
        @if (!avisoEdicao) {
        <div class="p-col">
          <div class="p-grid">
            <div class="p-col-12">
              <app-hierarquia-field
                [(ngModel)]="dadosFiltro.idHierarquia"
                name="idHierarquia"
                [disabled]="isHierarquiaDisabled()"
                (onChange)="onHierarquiaSelect($event)"
              />
            </div>
            @if (isHierarquiaDisabled()) {
            <small class="aviso-disabled p-col-12 p-pt-0" translate>
              avisoFerias.avisoHabilitarHierarquias
            </small>
            }
            <div class="p-col-12">
              <p-checkbox
                name="flIncluirFilhas"
                [label]="'escala.flIncluirFilhas' | translate"
                [binary]="true"
                [(ngModel)]="dadosFiltro.flIncluirFilhas"
                [disabled]="!dadosFiltro?.idHierarquia?.length"
              >
              </p-checkbox>
            </div>
            <div class="p-col-12">
              <app-turno-field
                name="idTurno"
                [(ngModel)]="dadosFiltro.idTurno"
                [fetchTurnos]="false"
                [listaTurnos]="turnos"
                [disabled]="!dadosFiltro?.idHierarquia?.length"
                (onChange)="resetUsuarioSelecionado('turno')"
              />
            </div>
          </div>
        </div>
        }
      </div>
      <div class="p-grid">
        <div class="p-col-12">
          <label class="label-input"> <span translate>avisoFerias.justificativa</span>: </label>
          <app-dropdown
            name="justificativa"
            [(ngModel)]="dadosFiltro.tpJustificativa"
            [options]="justificativas"
            [disabled]="avisoEdicao"
            required
          />
        </div>
      </div>
      <div class="p-grid">
        @if (shouldShowObservacao()) {
        <div class="p-col-12">
          <label class="label-input" translate>avisoFerias.observacao</label>
          <input
            name="dsObservacaoAusencia"
            type="text"
            #inputTextCount
            [(ngModel)]="dadosFiltro.dsObservacaoAusencia"
            maxlength="250"
            [disabled]="avisoEdicao"
            pInputTex
            required
          />
          <label class="label-input" translate>qtdRestanteCaracter</label>:
          {{ 250 - inputTextCount.value.length }}
        </div>
        }
        <div class="p-col-12 botao-salvar-ferias">
          <button
            type="button"
            [label]="'avisoFerias.salvarFerias' | translate"
            (click)="submit($event)"
            pButton
            class="botao-salvar disableBotaoSalvar"
            [class.disableBotaoSalvar]="!isSubmitEnabled(requiredForm)"
            [disabled]="!isSubmitEnabled(requiredForm) || loading"
            style="margin-right: 20px"
            [loading]="loading"
          ></button>
          @if (avisoEdicao) {
          <button
            type="button"
            [label]="'avisoFerias.cancelarEdicao' | translate"
            (click)="cancelarEdicao()"
            pButton
            class="botao-branco"
          ></button>
          }
        </div>
      </div>
    </div>
    }
  </div>
  }
</form>
