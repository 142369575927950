<div class="card" [ngClass]="{'selected': selected}">
  <div class="indicador-wrapper">
    <p class="titulo">
      <strong> {{ titulo }} </strong> <br/>
      <span> <span translate> indicadorFadiga.totalTeste </span>: {{ totalTestes | number }} </span>
    </p>
    <div class="energia-coluna">
      <span class="energia-texto">
        <span translate> indicadorFadiga.fadiga </span>
        <span translate> {{ nivelFadiga?.texto }} </span>
      </span>
      <div class="energia-wrapper">
        <div class="energia-value" [ngClass]="nivelFadiga.class">
          <img height="16" src="/assets/images/energia.svg" alt="Energia"/>
        </div>
      </div>
    </div>
  </div>
</div>
