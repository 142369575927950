import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicadorFadigaCardComponent } from './indicador-fadiga-card/indicador-fadiga-card.component';
import { SharedModule } from '../../shared.module';



@NgModule({
  declarations: [
    IndicadorFadigaCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    IndicadorFadigaCardComponent
  ]
})
export class IndicadorFadigaCardModule { }
