<app-loader [loading]="loading" />

@if (!loading) {
<div class="conteudoPageCrud">
  <app-titulo-edicao-crud [titulo]="titulo"></app-titulo-edicao-crud>
  <div class="p-grid cabecalho-listagem">
    <div class="p-col-12 ellipsis">
      <prontos-table
        [loading]="loading"
        selectionMode="single"
        (selectedChange)="exibirMenuEdicao($event)"
        [showHeader]="false"
        [selectWithRadioButton]="true"
        [lazy]="false"
        [totalRecords]="totalRegistros"
        [values]="arquivos"
        [columns]="colunas"
        [rows]="totalRegistros"
        [filter]="false"
        [first]="0"
        [showPesquisa]="true"
      >
      </prontos-table>
    </div>
  </div>
</div>
}

<!-- listagem de erros -->

@if (listaError.length > 0) {
<p-dialog
  id="idDialogError"
  [header]="'usuario.error' | translate"
  [(visible)]="isErrorTableUpload"
  modal="true"
  [height]="575.25"
  [width]="662.375"
>
  <prontos-table
    id="idListError"
    [loading]="loading"
    [selectWithCheckbox]="false"
    selectionMode="single"
    [showHeader]="true"
    [selectWithRadioButton]="false"
    [lazy]="false"
    [totalRecords]="totalRegistrosErros"
    [values]="listaError"
    [columns]="colunasError"
    [rows]="errosPorPagina"
    [first]="primeiro"
  >
  </prontos-table>
  <div class="p-col-12">
    <div style="text-align: center">
      <button
        style="margin-left: 15px"
        pButton
        class="botao-salvar"
        type="button"
        [label]="'usuario.entendi' | translate"
        (click)="hideListaError()"
      ></button>
    </div>
  </div>
</p-dialog>
}

<p-dialog
  [header]="arquivosSelecionado?.nmArquivo"
  [(visible)]="isShowDownload"
  (onHide)="hideDownload()"
  modal="true"
  [style]="{ width: '700px' }"
>
  @if (!isVideo) {
  <div style="padding-right: 20px"></div>
  } @if (isVideo) {
  <div style="padding-right: 20px">
    <app-video-js [urlVideo]="video" [urlPoster]="poster"></app-video-js>
  </div>
  }

  <div class="p-col-12">
    <div style="text-align: center">
      @if (!isVideo) {
      <button
        pButton
        class="botao-branco"
        type="button"
        [label]="'botaoCancelar' | translate"
        (click)="hideDownload()"
      ></button>
      } @if (!isVideo) {
      <button
        style="margin-left: 15px"
        pButton
        class="botao-salvar"
        type="button"
        [label]="'botaoDownload' | translate"
        (click)="baixarArquivo()"
      ></button>
      } @if (isVideo) {
      <button
        style="margin-left: 15px"
        pButton
        class="botao-salvar"
        type="button"
        [label]="'botaoConcluir' | translate"
        (click)="hideDownload()"
      ></button>
      }
    </div>
  </div>
</p-dialog>
