import { TipoJustificativaAusenciaEnum } from "../../enums/tipo-justificativa-ausencia.enum";

export class RequestIncluirAvisoAusencia {
  idUsuario: number[];
  dtInicioFerias: any;
  dtFimFerias: any;
  dsObservacaoAusencia: string;
  idHierarquia: number[];
  idTurno: number[];
  flIncluirFilhas: boolean = false;
  tpJustificativa: TipoJustificativaAusenciaEnum;
}
