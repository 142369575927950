<p-button
  class="legenda-button"
  label="Legenda"
  icon="pi pi-info"
  styleClass="p-button-outlined p-button-secondary"
  (onClick)="showLegenda()"
></p-button>

<p-dialog
  [(visible)]="flExibirLegenda"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '450px', 'max-width': '90%' }"
  [header]="'sinalizador.legenda' | translate"
  >
  <ul>
    @for (fator of fatoresRisco; track fator; let i = $index) {
      <li class="dot-wrapper">
        <span class="dot" [style]="{ background: fator }"></span>
        <span> {{ i }} </span>
        @if (i == 4) {
          <span translate> sinalizador.ouMais </span>
        }
        <span translate>
          {{ i <= 1 ? "sinalizador.fatoresRiscoSingular" : "sinalizador.fatoresRiscoPlural" }}
        </span>
      </li>
    }
  </ul>
</p-dialog>
