import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-legenda-sinalizador-prontidao',
  templateUrl: './legenda-sinalizador-prontidao.component.html',
  styleUrls: ['./legenda-sinalizador-prontidao.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendaSinalizadorProntidaoComponent {
  flExibirLegenda: boolean;
  fatoresRisco = [
    '#9ADC31',
    '#E7F497',
    '#E9D525',
    '#E99B25',
    '#FF4343'
  ]

  showLegenda() {
    this.flExibirLegenda = true;
  }

}
