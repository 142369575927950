import { ScoreFadigaEnum } from './../enums/score-fadiga.enum';
import { FormacaoBancoDadosEnum } from '../enums/formacao-banco-dados.enum';
import { SimNaoEnum } from '../enums/sim-nao-enum';
import { Util } from '../services/util.service';
import { FiltroGraficoDashboad } from './filtro-grafico-dashboard';

export class FiltroIndicadorFadiga {
  idHierarquia: number[];
  flIncluirFilhas: boolean = true;
  idCargo: number[];
  idTurno: number[];
  dtAnalise: string;
  scoreFadiga: ScoreFadigaEnum;

  constructor() {
  }

  toApi() {
    const filtros = Object.assign(new FiltroIndicadorFadiga(), this);
    return filtros;
  }

  convertFromFiltroDashbaord(filtrosDashboard: FiltroGraficoDashboad) {
    this.idHierarquia = filtrosDashboard.idHierarquia;
    this.flIncluirFilhas = filtrosDashboard.flIncluirFilhas;
    this.idCargo = filtrosDashboard.idCargo;
    this.idTurno = filtrosDashboard.idTurno;
    this.dtAnalise = filtrosDashboard.dtAnalise;
    this.scoreFadiga = filtrosDashboard.scoreFadiga;
    return this;
  }
}
