import { Component, Input } from '@angular/core';
import { NivelFadiga } from 'src/app/pages/dashboard/indicadores-fadiga/cores-nivel-fadiga';

@Component({
  selector: 'app-indicador-fadiga-card',
  templateUrl: './indicador-fadiga-card.component.html',
  styleUrls: ['./indicador-fadiga-card.component.scss']
})
export class IndicadorFadigaCardComponent {
  @Input() titulo: string;
  @Input() totalTestes: number;
  @Input() selected: boolean;
  @Input() nivelFadiga: NivelFadiga;
}
