import { ParamsSinalizadorProntidaoUsuariosHierarquia } from "./../../../../shared/tos/request/params-sinalizador-prontidao-usuarios-hierarquia";
import { Component, OnInit, ViewChild } from "@angular/core";
import { SinalizadorProntidaoService } from "src/app/shared/api-services/sinalizador-prontidao.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { RoleService } from "src/app/shared/services/role.service";
import { ResponseSinalizadorProntidaoMediaTO } from "src/app/shared/tos/response/response-sinalizador-prontidao-media.to";
import { FiltrosSinalizadorProntidaoComponent } from "./filtros-sinalizador-prontidao/filtros-sinalizador-prontidao.component";
import { LinhaTabelaSinalizadorProntidao } from "./tabela-sinalizador-prontidao/linha-tabela-sinalizador-prontidao";
import { ParamsSinalizadorProntidao } from "src/app/shared/tos/request/params-sinalizador-prontidao";
import { ResponseSinalizadorProntidao } from "src/app/shared/tos/response/response-sinalizador-prontidao.to";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { Util } from "src/app/shared/services/util.service";
import { TranslateService } from "@ngx-translate/core";
import { PeriodoEnum } from "src/app/shared/enums/periodo.enum";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { ResponseSinalizadorProntidaoConfiguracaoTO } from "src/app/shared/tos/response/response-sinalizador-prontidao-configuracao.to";

@Component({
  selector: "app-sinalizador-prontidao",
  templateUrl: "./sinalizador-prontidao.component.html",
  styleUrls: ["./sinalizador-prontidao.component.scss"],
})
export class SinalizadorProntidaoComponent implements OnInit {
  @ViewChild(FiltrosSinalizadorProntidaoComponent) filtrosProntidao: FiltrosSinalizadorProntidaoComponent;

  loading = false;
  isIdContratoRequired = false;
  showMediaGeral = true;
  showNumeroTabela = true;
  showAbaUsuarios = true;
  podeExpandirHierarquias = true;

  customErroTabela: string;
  filtros: ParamsSinalizadorProntidao = {
    cdPeriodo: PeriodoEnum.DIAS_30,
    flIncluirFilhas: true,
    idHierarquia: this.hierarquiaService.getHierarquiasUsuario(),
    flApenasUsuarioAtivos: false,
    idCargo: [],
    idTurno: [],
  } as any;
  media: ResponseSinalizadorProntidaoMediaTO;
  configuracao: ResponseSinalizadorProntidaoConfiguracaoTO;
  dadosTabela: LinhaTabelaSinalizadorProntidao[];
  listaContratos: SelectItem[] = [];

  idContrato: number;
  idxTabAtivo: number = 0;
  dynamicTableRows: number = 30;

  constructor(
    private sinalizadorProntidaoService: SinalizadorProntidaoService,
    private roleService: RoleService,
    private contratoService: ContratoService,
    private translateService: TranslateService,
    private hierarquiaService: HierarquiaService
  ) {}

  ngOnInit() {
    this.isIdContratoRequired =
      this.roleService.isRole(Permissao.ADMINISTRADOR) ||
      this.roleService.isRole(Permissao.GESTOR_SME) ||
      this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    this.showMediaGeral =
      this.roleService.isRole(Permissao.ADMINISTRADOR) ||
      this.roleService.isRole(Permissao.GESTOR_SME) ||
      this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    this.showNumeroTabela =
      this.roleService.isRole(Permissao.ADMINISTRADOR) ||
      this.roleService.isRole(Permissao.GESTOR_SME) ||
      this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    this.podeExpandirHierarquias = !this.roleService.isRole(Permissao.GESTOR);
    this.showAbaUsuarios = !this.roleService.isRole(Permissao.GESTOR);
    this.idxTabAtivo =
      this.showAbaUsuarios && this.hierarquiaService.getHierarquiasUsuario().length < 2 ? 1 : 0;
    console.log(this.idxTabAtivo);
    this.handleOnTabChange({ index: this.idxTabAtivo });
    this.getContratos();
  }

  getContratos() {
    if (this.isIdContratoRequired) {
      this.contratoService.getListaContratos().subscribe((contratos) => {
        this.listaContratos = Util.jsonArrayToSelectItem(contratos, "dsHierarquia", null, "idContrato");
        if (this.listaContratos.length === 1) {
          this.idContrato = this.listaContratos[0].value;
          this.handleOnContratoChange(this.idContrato);
        }
      });
    }
  }

  openFiltros() {
    this.filtrosProntidao.open();
  }

  isFiltrosValidos() {
    this.customErroTabela = "";
    if (this.isIdContratoRequired && !this.filtros?.idContrato) {
      this.customErroTabela = this.translateService.instant("sinalizador.erroSelecionarContrato");
      return false;
    }
    return true;
  }

  getSinalizador(
    endpoint: (filtros: ParamsSinalizadorProntidao) => Observable<ResponseSinalizadorProntidao>
  ) {
    if (this.isFiltrosValidos()) {
      this.loading = true;
      this.dadosTabela = [];
      endpoint(this.filtros).subscribe(
        (response) => {
          const dados = response.hierarquias || response.usuarios;
          this.loading = false;
          this.media = response.mediaGeral;
          this.configuracao = response.config;
          // this.dadosTabela = dados.filter((_, index) => index < 11).map(hierarquia => new LinhaTabelaSinalizadorProntidao().fromResponse(hierarquia))
          this.dadosTabela = dados.map((hierarquia) =>
            new LinhaTabelaSinalizadorProntidao().fromResponse(hierarquia)
          );
        },
        (err) => {
          this.loading = false;
          throw err;
        }
      );
    }
  }

  getUsuariosByHierarquia(linha: LinhaTabelaSinalizadorProntidao) {
    if (linha.usuariosCarregados) {
      return;
    }
    const filtros = new ParamsSinalizadorProntidaoUsuariosHierarquia().fromFiltrosHierarquia(
      this.filtros,
      this.media
    );
    linha.isLoading = true;
    this.sinalizadorProntidaoService
      .getUsuariosSinalizadorProntidaoByHierarquia(linha.idHierarquia, filtros)
      .subscribe(
        (response) => {
          linha.isLoading = false;
          linha.usuariosCarregados = true;
          const novasLinhas = response.map((usuario) =>
            new LinhaTabelaSinalizadorProntidao().fromUsuarioByHierarquiaResopnse(usuario)
          );
          this.dadosTabela = [...this.dadosTabela, ...novasLinhas];
          this.dynamicTableRows += novasLinhas.length + 1;
        },
        (err) => {
          linha.isLoading = false;
          throw err;
        }
      );
  }

  handleOnContratoChange(idContrato: number) {
    this.filtros.idContrato = idContrato;
    this.media = null;
    this.handleGetSinalizador(this.idxTabAtivo);
  }

  handleOnTabChange({ index }) {
    this.idxTabAtivo = index;
    this.handleGetSinalizador(index);
  }

  handleGetSinalizador(index: number) {
    const map = {
      0: this.sinalizadorProntidaoService.getHierarquiaSinalizadorProntidao.bind(
        this.sinalizadorProntidaoService
      ),
      1: this.sinalizadorProntidaoService.getUsuariosSinalizadorProntidao.bind(
        this.sinalizadorProntidaoService
      ),
    };
    this.idxTabAtivo = index;
    this.getSinalizador(map[index]);
  }

  onFiltrar(filtros: ParamsSinalizadorProntidao) {
    this.filtros = filtros;
    this.filtros.idContrato = this.idContrato;
    this.handleGetSinalizador(this.idxTabAtivo);
  }

  async acessarPrimeiroElemento() {
    return new Promise(async (res) => {
      if (this.isIdContratoRequired) {
        const hasLista = await Util.waitUntil(() => this.listaContratos?.length > 0);
        if (hasLista) {
          this.idContrato = this.listaContratos[0].value;
        } else {
          res(false);
        }
      }
      this.handleOnContratoChange(this.idContrato);
      await Util.waitUntil(() => !this.loading);
      res(true);
    });
  }

  showTable() {
    return this.isIdContratoRequired ? !!this.idContrato : true;
  }
}
