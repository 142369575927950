import { Component, Input } from '@angular/core';
import { ResponseSinalizadorProntidaoMediaTO } from 'src/app/shared/tos/response/response-sinalizador-prontidao-media.to';

@Component({
  selector: 'app-media-sinalizador-prontidao',
  templateUrl: './media-sinalizador-prontidao.component.html',
  styleUrls: ['./media-sinalizador-prontidao.component.scss']
})
export class MediaSinalizadorProntidaoComponent {
  @Input() media: ResponseSinalizadorProntidaoMediaTO;
  @Input() loading: boolean;
}
