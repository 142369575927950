<div class="card" [ngClass]="{'disabled': disabled}">
  <p class="resumo--text">
    <span class="resumo--number"> {{ value }} </span>
    <br/>
    <span class="resumo--title"> {{ title }} </span>
  </p>
  @if (badgeContent) {
    <em class="fas fa-info-circle info-badge"
      (mouseover)="filtrosOverlay.toggle($event)"
      (mouseleave)="filtrosOverlay.toggle($event)">
    </em>
  }
</div>

<p-overlayPanel #filtrosOverlay styleClass="resumo-overlay">
  <ng-container *ngTemplateOutlet="badgeContent"></ng-container>
</p-overlayPanel>
