<div class="content card">
  <p class="titulo" translate>sinalizador.mediaGeral</p>
  @if (!loading) {
    <p class="valores">
      <span> {{ media.pcNivelAlterado * 100 | number }}% </span> <br />
      <span translate> sinalizador.niveis </span>
    </p>
    <p class="valores">
      <span> {{ media.pcAcoesCriticas * 100 | number }}% </span> <br />
      <span translate> sinalizador.acoesCriticas </span>
    </p>
    <p class="valores">
      <span> {{ media.qtQueixasUsuarios | number : "1.2-2" }} </span> <br />
      <span translate> sinalizador.bemEstar </span>
    </p>
    <p class="valores">
      <span> {{ media.pcPreditorRisco * 100 | number }}% </span> <br />
      <span translate> sinalizador.preditorRisco </span>
    </p>
    <p class="valores">
      <span> {{ media.pcFadiga * 100 | number }}% </span> <br />
      <span translate> sinalizador.pcFadiga </span>
    </p>
  } @else {
    @for (i of [1, 2, 3, 4]; track i) {
      <p class="valores">
        <p-skeleton height="16px" width="90px" class="p-mb-1 p-d-block"></p-skeleton>
        <p-skeleton height="16px" width="90px" class="p-d-block"></p-skeleton>
      </p>
    }
  }
</div>

