import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { saveAs as importedSaveAs } from "file-saver";
import { FileUpload } from "primeng/fileupload";
import { forkJoin } from "rxjs";
import { RelatorioService } from "src/app/shared/api-services/relatorio.service";
import { ProntosTableComponent } from "src/app/shared/components/prontos-table/prontos-table.component";
import { AcaoFilter } from "src/app/shared/filters/acao-filter";
import { Column } from "src/app/shared/models/column.model";
import { IsMobileService } from "src/app/shared/services/is-mobile.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ErroTO } from "src/app/shared/tos/erro.to";
import { ResponseArquivoManualTO } from "src/app/shared/tos/response/response-arquivo-manual.to";
import { ResposeHistoricoUsuarioTO } from "src/app/shared/tos/response/response-historico-usuario.to";
import { SimpleCrudPage } from "../simple-crud-page";

@Component({
  selector: "informativo",
  templateUrl: "./informativo.component.html",
  styleUrls: ["./informativo.component.scss"],
})
export class InformativoComponent extends SimpleCrudPage implements OnInit {
  // Titulo do component
  title: string;
  // Instancia do video.js.
  vidObj: any;
  // Poster para ser usado no video.js
  poster: string;
  // URL do video a ser reproduzido.
  video: string;
  videoMP4: string;
  @ViewChild(FileUpload) fileUpload: FileUpload;
  @ViewChild(ProntosTableComponent) prontosTable: ProntosTableComponent;

  isErrorTableUpload: boolean;
  listaError: ErroTO[] = [];

  tituloEdicao: string;
  titulo: string;
  colunasError: Column[];
  colunas: Column[];
  itensPorPagina = 30;

  errosPorPagina = 5;
  primeiro = 0;
  totalRegistrosErros: number = 100000;

  filter: AcaoFilter;
  bodyLoad: boolean;
  loadButton: boolean;
  loadButtonDownload: boolean;

  loadButtonUpload: boolean;
  changeIcon: any;

  isExibirDownload: boolean;
  isExibirUpload: boolean;
  isVideo: boolean = false;

  formUsuario: UntypedFormGroup;

  recebeFiltro: string;

  desktopDevice: boolean;

  primeiroDiaMes: any;
  ultimoDiaMes: any;
  minimoDiaMes: any;

  botaoHistorico: boolean;
  showDialogHistorico: boolean;
  historicos: ResposeHistoricoUsuarioTO[] = [];
  botaoHistoricoDisabled: boolean;
  isShowDownload: boolean = false;
  arquivos: ResponseArquivoManualTO[];

  arquivosSelecionado: ResponseArquivoManualTO;
  url: string;

  constructor(
    private activatedRouter: ActivatedRoute,
    private translateService: TranslateService,
    private readonly sessionStorageService: SessionStorageService,
    private relatorioService: RelatorioService,
    private isMobileService: IsMobileService
  ) {
    super(activatedRouter);
  }

  ngOnInit() {
    this.detectarDispositivo();
    this.botaoEditar = false;
    this.botaoExcluir = false;
    this.botaoHistorico = false;
    this.bodyLoad = false;
    this.loadButton = false;
    this.loadButtonDownload = false;
    this.loadButtonUpload = false;

    this.inactivateEdition();

    this.configurarTabela();
    this.atualizarSourceObservable();
    //this.loadDialogFilter();

    this.titulo = this.translateService.instant("informativo.informativo");
  }

  ngAfterViewInit(): void {
    //this.alterarIconFileUpload();
  }

  getTituloColuna(traducao: string): string {
    return this.translateService.instant(traducao);
  }

  getTitulo(traducao: string): string {
    return this.translateService.instant(traducao);
  }

  atualizarSourceObservable(pagina?: number) {
    this.loading = true;
    forkJoin(
      this.relatorioService.listarItensDownlaod(),
      this.relatorioService.listaVideoTreinamento()
    ).subscribe(
      (resp) => {
        for (var i = 0; i < resp[1].length; i++) {
          resp[1][i].nmArquivo = resp[1][i].dsArquivo;
          resp[1][i].nmModulo = "Treinamento";
          resp[1][i].vlBytes = null;
        }
        this.arquivos = [...resp[0], ...resp[1]];
        this.totalRegistros = this.arquivos.length;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      }
    );
  }

  configurarTabela() {
    this.colunas = [
      //{ header: this.getTituloColuna('informativo.nmObjeto'), field: 'nmObjeto', width: '28%' },
      { header: this.getTituloColuna("informativo.nmArquivo"), field: "nmArquivo", width: "50%" },
      { header: this.getTituloColuna("informativo.nmModulo"), field: "nmModulo", width: "25%" },
      { header: this.getTituloColuna("informativo.vlBytes"), field: "vlBytes", width: "21%" },
    ];
  }

  configurarListaErrorUpload() {
    this.colunasError = [{ header: this.getTituloColuna("usuario.error"), field: "dsErro" }];
  }

  getMessageDialog(chave: string): string {
    return this.translateService.instant(chave);
  }

  exibirMenuEdicao(itemSelected: any) {
    const exibirBotoes: boolean = itemSelected != null;
    if (exibirBotoes) {
      this.isShowDownload = exibirBotoes;
      this.isVideo = itemSelected.dsUrlImagemInicial != undefined && itemSelected.dsUrlImagemInicial != "";

      this.arquivosSelecionado = itemSelected;
      if (this.isVideo) {
        this.url = itemSelected.resolucoes.find((x) => x.dsResolucao == "padrao").url;
        // Titulo do component
        this.title = this.arquivosSelecionado.nmArquivo;
        // Poster para ser usado no video.js
        this.poster = this.arquivosSelecionado.dsUrlImagemInicial;
        this.video = this.url;
        this.videoMP4 = itemSelected.urlMp4;
      }
    }
  }

  hideDownload() {
    this.isShowDownload = false;
    this.video = "";
    this.videoMP4 = "";
    this.isVideo = false;
  }

  baixarArquivo() {
    this.loading = true;
    this.relatorioService.arquivoDownlaod(this.arquivosSelecionado.nmObjeto).subscribe(
      (response) => {
        const nome = this.arquivosSelecionado.nmObjeto;
        this.relatorioService.downloadRelatorio(response.dsUrlArquivo).subscribe(
          (file) => {
            importedSaveAs(new Blob([file]), nome);
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        );
      },
      (error) => {
        this.botaoHistoricoDisabled = false;
        throw error;
      }
    );
  }

  carregarFilterGlobal() {
    const filtroGlobal = this.sessionStorageService.getItem<string>("filtroUsuarioGlobal");
    this.recebeFiltro = filtroGlobal;
    if (this.recebeFiltro != null) {
      this.prontosTable.filterGlobal(this.recebeFiltro, "contains");
    }
  }

  detectarDispositivo() {
    if (this.isMobileService.isMobile()) {
      this.desktopDevice = false;
    } else {
      this.desktopDevice = true;
    }
  }

  getCalendarTranslate() {
    return this.translateService.instant("calendar");
  }
}
