<app-loader [loading]="loader" />

@if (!loader) {
<p-table styleClass="p-datatable-striped" [value]="resultados" dataKey="id">
  <ng-template pTemplate="header">
    <tr>
      <th scope="col" style="width: 2%"></th>
      <th scope="col" style="width: 8%" translate>resultados.data</th>
      <th scope="col" style="width: 14%" translate>resultados.usuario</th>
      <th scope="col" style="width: 8%" translate>resultados.acaoSugerida</th>
      <th scope="col" style="width: 12%" translate>resultados.nivel</th>
      <th scope="col" style="width: 11%" translate>resultados.classificacao</th>
      <th scope="col" style="width: 7%" translate>resultados.escore</th>
      <th scope="col" style="width: 9%" translate>resultados.atencao</th>
      <th scope="col" style="width: 10%" translate>resultados.controleImpulsos</th>
      <th scope="col" style="width: 8%" translate>resultados.tempo</th>
      <th scope="col" style="width: 11%" translate>resultados.concentracao</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-resultado let-expanded="expanded">
    <tr [pRowToggler]="resultado" style="cursor: pointer">
      <td>
        <div>
          <em
            [ngClass]="
              expanded
                ? 'pi pi-chevron-down'
                : 'pi
            pi-chevron-right'
            "
          ></em>
        </div>
      </td>
      <td>{{ resultado.dtExecucao | date : "dd/MM/yyyy hh:mm:ss" }}</td>
      <td style="text-align: center">{{ resultado.dsNomeUsuario }} - {{ resultado.cdMatriculaUsuario }}</td>
      <td style="text-align: center">{{ resultado.dsSiglaAcaoSugerida }}</td>
      <td style="text-align: center">{{ resultado.dsNivelResultado }}</td>
      <td style="text-align: center">{{ resultado.dsSiglaClassificacaoFinal }}</td>
      <td style="text-align: center">{{ resultado.vlEscorePerguntas }}</td>
      <td style="text-align: center">
        {{ resultado.resultadoDado[0].vlPercentualOmissao }}
        <br />
        {{ resultado.resultadoDado[0].dsSiglaClassificacaoResultadoAtencao }}
      </td>
      <td style="text-align: center">
        {{ resultado.resultadoDado[0].vlPercentualErro }}
        <br />
        {{ resultado.resultadoDado[0].dsSiglaclassificacaoResultadoControleImpulsos }}
      </td>
      <td style="text-align: center">
        {{ resultado.resultadoDado[0].vlMediaTempoAcerto }}
        <br />
        {{ resultado.resultadoDado[0].dsSiglaClassificacaoResultadoTempoReacao }}
      </td>
      <td style="text-align: center">
        {{ resultado.resultadoDado[0].vlVariacaoAcerto }}
        <br />
        {{ resultado.resultadoDado[0].dsSiglaClassificacaoResultadoConcentracao }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-resultado>
    <tr>
      <td [attr.colspan]="12">
        <div class="p-grid">
          <div class="p-col-1"></div>
          <div class="p-col-11">
            <div>
              <strong>{{ resultado.perguntasMarcadas.length }} Perguntas Marcadas: </strong>
            </div>
            @for (pergunta of resultado.perguntasMarcadas; track pergunta) {
            <div class="pergunta">
              {{ pergunta }}
            </div>
            }
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
}
