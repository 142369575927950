@if (showDialog) {
<p-dialog
  id="dialog-historico-ferias"
  [modal]="true"
  [showHeader]="true"
  [header]="'avisoFerias.historicoAviso' | translate"
  [(visible)]="showDialog"
  [closable]="true"
  [style]="{ width: '90%', 'max-width': '780px' }"
>
  <app-loader [loading]="loading" />
  @if (!loading) {
  <p class="label-input"><span translate> avisoFerias.usuario </span>: {{ usuario.dsNome }}</p>
  <prontos-table
    #dt
    (editItem)="editarAviso($event)"
    (deleteItem)="handleDeletar($event)"
    (selectedChange)="verificarPodeEditarExcluir($event)"
    selectionMode="single"
    [showHeader]="true"
    [paginator]="false"
    [selectWithRadioButton]="true"
    [lazy]="false"
    [values]="historico"
    [columns]="colunas"
    [botaoEditar]="podeEditar"
    [botaoExcluir]="true"
  />
  }
</p-dialog>
}
