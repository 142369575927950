import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ResultadosComponent } from "./resultados/resultados.component";
import { InformativoComponent } from "./informativo/informativo.component";
import { DashboardContainerComponent } from "./dashboard/dashboard-container/dashboard-container.component";
import { AuthKeyCloakGuard } from "../shared/services/guards/auth-keycloak.guard";
import { Permissao } from "../shared/enums/permissao.enum";
import { AvisoAusenciaComponent } from "./aviso-de-ausencia/aviso-de-ausencia.component";
import { InicioComponent } from "./inicio/inicio.component";
import { CanAccessGuard } from "../shared/services/guards/can-acess.guard";
import { RoleService } from "../shared/services/role.service";

const routes: Routes = [
  {
    path: "inicio",
    component: InicioComponent,
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "dashboard",
    component: DashboardContainerComponent,
    canActivate: [AuthKeyCloakGuard],
    children: [
      {
        path: "",
        canActivate: [AuthKeyCloakGuard, CanAccessGuard],
        data: { allowed: [RoleService.DASHBOARD_REGEX] },
        component: DashboardComponent,
      },
      {
        path: "testes",
        canActivate: [AuthKeyCloakGuard, CanAccessGuard],
        data: { allowed: [RoleService.ACAO_DEFINIDA_REGEX] },
        loadChildren: () => import("../pages/testes/testes.module").then((m) => m.TestesModule),
      },
    ],
  },
  {
    path: "testes",
    canActivate: [AuthKeyCloakGuard, CanAccessGuard],
    data: { allowed: [RoleService.ACAO_DEFINIDA_REGEX] },
    loadChildren: () => import("../pages/testes/testes.module").then((m) => m.TestesModule),
  },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "resultados",
    component: ResultadosComponent,
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "informativo",
    component: InformativoComponent,
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "configuracao",
    loadChildren: () => import("../pages/configuracao/configuracao.module").then((m) => m.ConfiguracaoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "relatorios",
    loadChildren: () => import("../pages/relatorios/relatorios.module").then((m) => m.RelatoriosModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "validacao-acesso",
    loadComponent: () =>
      import("../pages/validacao-acesso/validacao-acesso.component").then((m) => m.ValidacaoAcessoComponent),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "aviso-de-ausencia",
    component: AvisoAusenciaComponent,
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "nivel-resultado",
    loadChildren: () =>
      import("../pages/nivel-resultado/nivel-resultado.module").then((m) => m.NivelResultadoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "acao",
    loadChildren: () => import("../pages/acao/acao.module").then((m) => m.AcaoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "usuario",
    loadChildren: () => import("../pages/usuario/usuario.module").then((m) => m.UsuarioModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "classificacao-grupo",
    loadChildren: () =>
      import("../pages/classificacao-grupo/classificacao-grupo.module").then(
        (m) => m.ClassificacaoGrupoModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "classificacao-resultado",
    loadChildren: () =>
      import("../pages/classificacao-resultado/classificacao-resultado.module").then(
        (m) => m.ClassificacaoResultadoModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "parametro-referencia",
    loadChildren: () =>
      import("../pages/parametros-referencia/parametro-referencia.module").then(
        (m) => m.ParametroReferenciaModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "regra-acao-sugerida",
    loadChildren: () =>
      import("../pages/regra-acao-sugerida/regra-acao-sugerida.module").then(
        (m) => m.RegraAcaoSugeridaModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "turno",
    loadChildren: () => import("../pages/turno/turno.module").then((m) => m.TurnoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "cargo",
    loadChildren: () => import("../pages/cargo/cargo.module").then((m) => m.CargoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "exportacao-dados-brutos",
    loadChildren: () =>
      import("../pages/exportacao-dados-brutos/exportacao-dados-brutos.module").then(
        (m) => m.ExportacaoDadosBrutosModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "hierarquia",
    loadChildren: () => import("../pages/hierarquia/hierarquia.module").then((m) => m.HierarquiaModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "novidades",
    loadChildren: () => import("../pages/novidades/novidades.module").then((m) => m.NovidadesModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "dicas",
    loadChildren: () => import("../pages/dicas/dicas.module").then((m) => m.DicasModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "central-notificacoes",
    loadChildren: () =>
      import("../pages/central-notificacoes/central-notificacoes.module").then(
        (m) => m.CentralNotificacoesModule
      ),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "medicao-servicos",
    loadChildren: () =>
      import("../pages/medicao-servicos/medicao-servicos.module").then((m) => m.MedicaoServicosModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "acolhimento",
    loadChildren: () => import("../pages/acolhimento/acolhimento.module").then((m) => m.AcolhimentoModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "desvio-fadiga",
    loadChildren: () =>
      import("../pages/desvio-fadiga/desvio-fadiga.module").then((m) => m.DesvioFadigaModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "escala",
    loadChildren: () => import("../pages/escala/escala.module").then((m) => m.EscalaModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "checklist",
    loadChildren: () => import("../pages/checklist/checklist.module").then((m) => m.ChecklistModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "suporte",
    loadChildren: () => import("../pages/suporte/suporte.module").then((m) => m.SuporteModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "faq",
    loadChildren: () => import("../pages/faq/faq.module").then((m) => m.FaqModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "gestao-temporaria",
    loadChildren: () =>
      import("../pages/gestao-temporaria/gestao-temporaria.module").then((m) => m.GestaoTemporariaModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "permissoes",
    loadChildren: () => import("../pages/permissoes/permissoes.module").then((m) => m.PermissoesModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "perguntas",
    loadChildren: () => import("./perguntas/perguntas.module").then((m) => m.PerguntasModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "liberacao-app",
    loadChildren: () => import("./liberacao-app/liberacao-app.module").then((m) => m.LiberacaoAppModule),
    canActivate: [AuthKeyCloakGuard],
  },
  {
    path: "acidente",
    loadChildren: () => import("./acidente/acidente.module").then((m) => m.AcidenteModule),
    canActivate: [AuthKeyCloakGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}

export const routedComponents = [DashboardComponent, ResultadosComponent];
