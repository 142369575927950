import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerta-dashboard',
  templateUrl: './alerta-dashboard.component.html',
  styleUrls: ['./alerta-dashboard.component.scss']
})
export class AlertaDashboardComponent implements OnInit {
  showModal: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

}
