import { Component, OnInit, inject } from "@angular/core";

import { RoleService } from "src/app/shared/services/role.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
  selector: "app-inicio",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.scss"],
})
export class InicioComponent implements OnInit {
  private roleService: RoleService = inject(RoleService);
  private routerService: RouterService = inject(RouterService);

  ngOnInit(): void {
    this.checkDashboardPermission();
  }

  checkDashboardPermission() {
    const canAccessTestePendente = this.roleService.isRole(RoleService.ACAO_DEFINIDA_REGEX);
    const canAccessDashboard = this.roleService
      .getRoles()
      .some((role) => new RegExp(RoleService.DASHBOARD_REGEX).test(role));
    if (canAccessDashboard) {
      this.routerService.toDashboard();
    } else if (canAccessTestePendente) {
      this.routerService.toListaTestePendente();
    }
  }
}
