import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-dashboard',
  templateUrl: './box-dashboard.component.html',
  styleUrls: ['./box-dashboard.component.scss']
})
export class BoxDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() bigger: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
