import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { SegurancaPsicologicaEnum } from 'src/app/shared/enums/seguranca-psicologica.enum';
import { ResponseGraficoSegurancaPsicologicaTO } from 'src/app/shared/tos/response/response-grafico-segurancao-psicologica.to';

@Component({
  selector: 'app-grafico-seguranca-psicologica',
  templateUrl: './grafico-seguranca-psicologica.component.html',
  styleUrls: ['./grafico-seguranca-psicologica.component.scss']
})
export class GraficoSegurancaPsicologicaComponent implements OnChanges {
  @Input() dadosGrafico: ResponseGraficoSegurancaPsicologicaTO[];
  chart: any;
  qtdAnsiedade: number = 0;
  qtdApatia: number = 0;
  qtdAprendizagem: number = 0;
  qtdConforto: number = 0;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dadosGrafico) {
      this.qtdAnsiedade = this.getQuantidadePorResultado(SegurancaPsicologicaEnum.ANSIEDADE);
      this.qtdApatia = this.getQuantidadePorResultado(SegurancaPsicologicaEnum.APATIA);
      this.qtdAprendizagem = this.getQuantidadePorResultado(SegurancaPsicologicaEnum.APRENDIZAGEM);
      this.qtdConforto = this.getQuantidadePorResultado(SegurancaPsicologicaEnum.CONFORTO);
      this.configurarGrafico(this.qtdAnsiedade, this.qtdApatia, this.qtdAprendizagem, this.qtdConforto)
    }
  }

  getQuantidadePorResultado(cdResultado: SegurancaPsicologicaEnum) {
    const item = this.dadosGrafico?.find(d => d.cdResultado === cdResultado);
    return item ? item.qtRegistros : 0;
  }

  configurarGrafico(qtdAnsiedade: number, qtdApatia: number, qtdAprendizagem: number, qtdConforto: number) {
    const corConforto = '#b6c9e2';
    const corAprendizagem = '#3853aa';
    const corApatia = '#8da4c2';
    const corAnsiedade = '#4b98ff';

    const pontosConforto = this.gerarManyPontos(qtdConforto, 150, 350, 600, 800);
    const pontosAprendizagem = this.gerarManyPontos(qtdAprendizagem, 700, 900, 600, 800);
    const pontosApatia = this.gerarManyPontos(qtdApatia, 150, 350, 150, 350);
    const pontosAnsiedade = this.gerarManyPontos(qtdAnsiedade, 700, 900, 150, 350);

    this.chart = new Chart({
      chart: {
        type: 'scatter',
        marginLeft: 50,
      },
      title: {
        text: ''
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            chart: {
              marginLeft: 10
            }
          }
        }]
      },
      plotOptions: {
        series: {
          softThreshold: false,
          stickyTracking: false,
          cursor: 'pointer',
        },
        scatter: {
          marker: {
            symbol: 'circle'
          }
        }
      },
      xAxis: {
        min: 0,
        max: 1000,
        startOnTick: true,
        endOnTick: false,
        labels: {
          enabled: false
        },
        tickPositioner: function () {
          let maxTick = Math.ceil(this.max);
          const minTick = Math.floor(this.min);
          if ((maxTick + minTick) % 2 === 1) {
            maxTick += 1;
          }
          const midTick = (maxTick + minTick) / 2;
          return [0, midTick];
        },
        gridLineWidth: 1,
        title: {
          text: '',
        },
      },
      yAxis: {
        min: 0,
        max: 1000,
        startOnTick: false,
        endOnTick: false,
        labels: {
          enabled: false
        },
        tickPositioner: function () {
          // Only render three ticks, two on the edges and one in the middle
          const mid = (this.min + this.max) / 2;
          return [0, mid];
        },
        title: {
          text: '',
        }
      },
      tooltip: {
        enabled: false,
      },
      series: [{
        name: 'Conforto',
        color: corConforto,
        data: pontosConforto,
        type: 'scatter',
        showInLegend: false,
      }, {
        name: 'Aprendizagem',
        color: corAprendizagem,
        data: pontosAprendizagem,
        type: 'scatter',
        showInLegend: false,
      }, {
        name: 'Apatia',
        color: corApatia,
        data: pontosApatia,
        type: 'scatter',
        showInLegend: false,
      }, {
        name: 'Ansiedade',
        color: corAnsiedade,
        data: pontosAnsiedade,
        type: 'scatter',
        showInLegend: false,
      }],
    })
  }

  gerarManyPontos(qtd: number, minX: number, maxX: number, minY: number, maxY: number) {
    const coordenadas = [];
    for (let i = 0; i < qtd; i++) {
      coordenadas.push(this.gerarPontoAleatorio(minX, maxX, minY, maxY))
    }
    return coordenadas;
  }

  gerarPontoAleatorio(minX: number, maxX: number, minY: number, maxY: number) {
    const x = Math.floor(Math.random() * (maxX - minX) + minX);
    const y = Math.floor(Math.random() * (maxY - minY) + minY);
    return [x, y];
  }

}
