import { PeriodoEnum } from "../../enums/periodo.enum";
import { ParamsSinalizadorProntidao } from "./params-sinalizador-prontidao";
import { ResponseSinalizadorProntidaoMediaTO } from "../response/response-sinalizador-prontidao-media.to";

export class ParamsSinalizadorProntidaoUsuariosHierarquia {
  idContrato: number;
  cdPeriodo: PeriodoEnum;
  idCargo: number[];
  idTurno: number[];
  cdMatriculaUsuario: string;
  pcNivelAlterado: number;
  pcAcoesCriticas: number;
  qtQueixasUsuarios: number;
  pcPreditorRisco: number;
  pcFadiga: number;
  flApenasUsuarioAtivos: boolean;

  fromFiltrosHierarquia(filtros: ParamsSinalizadorProntidao, media: ResponseSinalizadorProntidaoMediaTO) {
    this.idContrato = filtros.idContrato;
    this.cdPeriodo = filtros.cdPeriodo;
    this.idCargo = filtros.idCargo;
    this.idTurno = filtros.idTurno;
    this.cdMatriculaUsuario = filtros.cdMatriculaUsuario;
    this.pcNivelAlterado = media.pcNivelAlterado || 0;
    this.pcAcoesCriticas = media.pcAcoesCriticas || 0;
    this.qtQueixasUsuarios = media.qtQueixasUsuarios || 0;
    this.pcPreditorRisco = media.pcPreditorRisco || 0;
    this.pcFadiga = media.pcFadiga || 0;
    this.flApenasUsuarioAtivos = filtros.flApenasUsuarioAtivos;
    return this;
  }
}
