import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FiltroGraficoDashboad } from 'src/app/shared/models/filtro-grafico-dashboard';
import { ResponseGraficoSegurancaPsicologicaTO } from 'src/app/shared/tos/response/response-grafico-segurancao-psicologica.to';

@Component({
  selector: 'app-seguranca-psicologica',
  templateUrl: './seguranca-psicologica.component.html',
  styleUrls: ['./seguranca-psicologica.component.scss']
})
export class SegurancaPsicologicaComponent implements OnChanges {
  @Input() dados: ResponseGraficoSegurancaPsicologicaTO[];
  @Input() filtros: FiltroGraficoDashboad;

  total: number;
  periodoAnalisado: string = '-';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dados) {
      this.total = this.calcularTotalAvaliacoes(changes.dados.currentValue);
    }
    if (changes.filtros) {
      this.periodoAnalisado = this.calcularPeriodoAnalisado(changes.filtros.currentValue);
    }
  }

  calcularTotalAvaliacoes(dados: ResponseGraficoSegurancaPsicologicaTO[]) {
    return dados ? dados.reduce((prev, cur) => prev + cur.qtRegistros, 0) : 0;
  }

  calcularPeriodoAnalisado(filtros: FiltroGraficoDashboad) {
    if (!filtros) return '-';
    return `${new Date(filtros.dtInicio).toLocaleDateString()} - ${new Date(filtros.dtFim).toLocaleDateString()}`;
  }

}
