import { ResponseSinalizadorProntidaoUsuarioItemTO } from "./../tos/response/response-sinalizador-prontidao-usuario-item.to";
import { ParamsSinalizadorProntidaoUsuariosHierarquia } from "./../tos/request/params-sinalizador-prontidao-usuarios-hierarquia";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Util } from "../services/util.service";
import { ParamsSinalizadorProntidao } from "../tos/request/params-sinalizador-prontidao";
import { ResponseSinalizadorProntidao } from "../tos/response/response-sinalizador-prontidao.to";
import { DownloadService } from "./download.service";

@Injectable({
  providedIn: "root",
})
export class SinalizadorProntidaoService {
  constructor(private httpClient: HttpClient, private downloadService: DownloadService) {}

  private parseToApi(filtros) {
    const filtrosCopy = Util.deepClone(filtros);
    filtrosCopy.flApenasUsuarioAtivos = !filtrosCopy.flApenasUsuarioAtivos;
    return filtrosCopy;
  }

  getHierarquiaSinalizadorProntidao(
    filtros: ParamsSinalizadorProntidao
  ): Observable<ResponseSinalizadorProntidao> {
    const params = Util.objectToQueryString(this.parseToApi(filtros));
    return this.httpClient.get<ResponseSinalizadorProntidao>(
      `/report-api/v1/sinalizador-prontidao/hierarquia`,
      { params }
    );
  }

  getUsuariosSinalizadorProntidaoByHierarquia(
    idHierarquia: number,
    filtros: ParamsSinalizadorProntidaoUsuariosHierarquia
  ): Observable<ResponseSinalizadorProntidaoUsuarioItemTO[]> {
    let params = Util.objectToQueryString(this.parseToApi(filtros));
    return this.httpClient.get<ResponseSinalizadorProntidaoUsuarioItemTO[]>(
      `/report-api/v1/sinalizador-prontidao/hierarquia/${idHierarquia}/usuarios`,
      { params }
    );
  }

  getUsuariosSinalizadorProntidao(
    filtros: ParamsSinalizadorProntidao
  ): Observable<ResponseSinalizadorProntidao> {
    let params = Util.objectToQueryString(this.parseToApi(filtros));
    return this.httpClient.get<ResponseSinalizadorProntidao>(`/report-api/v1/sinalizador-prontidao/usuario`, {
      params,
    });
  }

  downloadSinalizadorProntidaoService(filtros: ParamsSinalizadorProntidaoUsuariosHierarquia) {
    return this.downloadService.downloadBlob(
      `/report-api/v1/sinalizador-prontidao/gerar`,
      this.parseToApi(filtros)
    );
  }
}
