<div class="container">
  <div class="header-container">
    <app-aviso-hierarquia-temporaria></app-aviso-hierarquia-temporaria>

    @if (podeVerPendentestes && !readOnly) {
      <button
        class="botao-toggle-testes green hide-mobile"
        (click)="toggleColunaTestes()"
        [class.show-teste]="exibirColunaTestes"
        >
        <em class="material-icons">chevron_left</em>
      </button>
    }
  </div>

  <div class="hide-xl">
    <ng-container *ngTemplateOutlet="alertaTemplate"></ng-container>
  </div>

  <div class="dashboard-columns">
    <div class="colum-graficos">
      <router-outlet></router-outlet>
    </div>

    @if (podeVerPendentestes && !readOnly) {
      <div
        class="container-cards hide-mobile"
        [attr.aria-hidden]="exibirColunaTestes"
        [class.hidden]="!exibirColunaTestes"
        >
        <ng-container *ngTemplateOutlet="alertaTemplate"></ng-container>
        <div class="container-teste-pendente-acao-definida">
          <app-testes-pendentes-list [readOnly]="readOnly" [responsible]="false"></app-testes-pendentes-list>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #alertaTemplate>
  @if (isAlerta) {
    <app-alerta-dashboard></app-alerta-dashboard>
  }
</ng-template>
