import { HistoricoClassificacaoFinal } from "./historico-classificacao-final.mode";
import { HistoricoAcao } from "./historico-acao.model";
import { AcaoDefinir } from "./acao-definir.model";
import { HistoricoNivelResultado } from "./historio-nivel-resultado.model";
import { TipoEntrevistaDirecionadaEnum } from "../enums/tipo-entrevista-direcionada.enum";
import { HistoricoAcaoDefinida } from "./historico-acao-definida";

class PreDefinirAcaoTO {
  cdMatricula: string;
  dsAcaoPreDefinida: string;
  dsJustificativaAcao: string;
  dsUsuario: string;
  dtAcaoPreDefinida: Date;
  idAcaoPreDefinida: number;
}

export class DetalheAcaoADefinir {
  idTesteExecutado: number;
  idUsuario: number;
  dsUsuario: string;
  cdMatricula: string;
  dtExecucao: Date;
  dsCargo: string;
  dsHierarquia: string[];
  dsAcaoSugerida: string;
  cdEvoluiuRegrediu: string;
  cdEntrevistaDirecionada: TipoEntrevistaDirecionadaEnum;
  histClassifFinal: HistoricoClassificacaoFinal[];
  histAcao: HistoricoAcao[];
  acaoDefinir: AcaoDefinir[];
  histNivelResultado: HistoricoNivelResultado[];
  histNivelResultadoErroInst: Date[];
  histNivelResultadoMedia: HistoricoNivelResultado[];
  histNivelResultadoIntrajornada: HistoricoNivelResultado[];
  histAcaoDefinida: HistoricoAcaoDefinida[];
  preDefinicaoAcao?: PreDefinirAcaoTO;
}
