import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResponseAlertaSeguranca } from '../tos/response/response-alerta-seguranca';

@Injectable({
  providedIn: 'root'
})
export class AlertaSegurancaService {
  private cache = {};
  private static GET_ALERTA_KEY = 'GET_ALERTA_KEY';

  constructor(private httpClient: HttpClient) { }

  private versionAPI: string = '/v1';
  private path: string = '/alerta-seguranca';

  getAlertaSeguranca(): Observable<ResponseAlertaSeguranca> {
    if (this.cache[AlertaSegurancaService.GET_ALERTA_KEY]) return of(this.cache[AlertaSegurancaService.GET_ALERTA_KEY]);
    return this.httpClient.get<ResponseAlertaSeguranca>(`${this.versionAPI + this.path}/`).pipe(
      tap(r => this.cache[AlertaSegurancaService.GET_ALERTA_KEY] = r)
    );
  }
}

