<p-dialog
  modal="true"
  [style]="{ width: '700px' }"
  [blockScroll]="true"
  [header]="titulo"
  [(visible)]="isPopupVisible"
>
  <div class="ellipsis">
    <app-loader [loading]="loading" />
    @if (!loading) {
    <prontos-table
      [showHeader]="true"
      [selectWithRadioButton]="false"
      [values]="dados"
      [totalRecords]="totalRegistros"
      [columns]="colunas"
      [rows]="30"
      [first]="0"
      [selectWithCheckbox]="false"
      [filter]="false"
    ></prontos-table>
    }
  </div>
</p-dialog>
