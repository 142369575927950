import { Component, OnInit } from '@angular/core';
import { ResultadosService } from 'src/app/shared/api-services/resultados.service';
import { TesteExecutadoDadoUltimos100 } from 'src/app/shared/models/teste-executado-dado-ultimos-100.model';


@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss']
})
export class ResultadosComponent implements OnInit {
  loader: boolean;
  resultados: TesteExecutadoDadoUltimos100[];

  cols: any[];

  constructor(private resultadosService: ResultadosService) { }

  ngOnInit() {
    this.loader = true;

    this.resultadosService.getResultados().subscribe(
      retorno => {
        this.resultados = retorno
          .filter(resultado => resultado.resultadoDado.length != 0)
          .map((resultado, index) => {
            resultado.id = index + 1;
            return resultado;
          });

        this.loader = false;
      }, error => {
        this.loader = false;
        throw error;
      }
    );
  }

  atualizaLoader() {

  }
}

