<app-card-chart-dashboard
  [isFilter]="isFilter"
  [type]="type"
  [opcoesFiltro]="opcoesFiltro"
  [data]="data"
  (valorId)="valorId.emit($event)"
  (atualizarGraficos)="atualizarGraficos.emit($event)"
  (expandir)="expandirGrafico()">
</app-card-chart-dashboard>

<p-dialog
  [modal]="true"
  [showHeader]="true"
  header=""
  [(visible)]="showModal"
  [closable]="true"
  [style]="{'width': '90%', 'max-width': '1100px', height: '90%'}"
>
  <app-card-chart-dashboard
    #cardChartExpandido
    class="grafico-expandido"
    [isGraficoExpandido]="true"
    [isFilter]="isFilter"
    [type]="type"
    [opcoesFiltro]="opcoesFiltro"
    [exibirExpandido]="false"
    [data]="data"
    (valorId)="valorId.emit($event)"
    (atualizarGraficos)="atualizarGraficos.emit($event)">
  </app-card-chart-dashboard>
</p-dialog>
