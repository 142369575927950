import { ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { RouterService } from "../router-service.service";
import { RoleService } from "../role.service";

@Injectable({
  providedIn: "root",
})
export class CanAccessGuard {
  constructor(protected roleService: RoleService, private routerService: RouterService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowed: string[] = route.data.allowed;
    const userRoles = this.roleService.getRoles();
    const isAllowed = allowed.some((key) => {
      return userRoles.some((role) => new RegExp(key).test(role));
    });
    if (!isAllowed) {
      this.routerService.toInicio();
      return false;
    }
    return true;
  }
}
