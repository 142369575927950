import { GraficoDashboard } from "src/app/shared/models/grafico-dashboard.model";

export interface ItemGraficoDashboard {
  isLoading: boolean;
  dados: GraficoDashboard;
  chave: string;
  permissoes: string[];
  hasError: boolean;
}

export const LISTA_GRAFICO_DASHBOARD: ItemGraficoDashboard[] = [
  {
    chave: "RESULTADO_AVALIACAO",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_RESULTADO_AVALIACAO"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "ADESAO",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_ADESAO"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "PARAMETROS_ANALISE",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_PARAM_DESVIO"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "QUEIXAS",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_BEM_ESTAR"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "ESTATISTICA_ACOES",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_EST_ACOES"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "GESTAO_ACOES",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_GESTAO_ACOES"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "ACOES_DEFINIDAS",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_ACOES_DEFINIDAS"],
    isLoading: false,
    dados: null,
    hasError: false,
  },

  {
    chave: "EMOCIOGRAMA",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_EMOCIOGRAMA"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
  {
    chave: "GESTAO_SAUDE",
    permissoes: ["RL_VISUALIZAR_DASHBOARD_GESTAO_SAUDE"],
    isLoading: false,
    dados: null,
    hasError: false,
  },
];
