<p-table
  id="tabela-sinalizador"
  [sortMode]="!useHierarquiaSort ? 'single' : null"
  [value]="dados"
  [dataKey]="dataKey"
  [groupRowsBy]="groupRowsBy"
  [rowGroupMode]="showChildren && 'subheader'"
  [scrollable]="true"
  [paginator]="true"
  [rows]="dynamicTableRows"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        tooltipPosition="top"
        [pTooltip]="
          (isHierarquia ? 'sinalizador.dsHierarquiaTooltip' : 'sinalizador.dsNomeUsuarioTooltip') | translate
        "
        (click)="onChangeOrdenacao('dsHierarquia')"
        [pSortableColumn]="!useHierarquiaSort ? 'dsNomeUsuario' : null"
        translate
      >
        {{ tituloColunaNome }}
        @if (!useHierarquiaSort) {
        <p-sortIcon field="dsNomeUsuario"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'dsHierarquia' }"
        ></ng-template>
        }
      </th>
      @if (showColunaHierarquia) {
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.dsHierarquiaTooltip' | translate"
        (click)="onChangeOrdenacao('dsNomeHierarquia')"
        [pSortableColumn]="!useHierarquiaSort ? 'dsNomeHierarquia' : null"
        translate
      >
        usuario.hierarquia @if (!useHierarquiaSort) {
        <p-sortIcon field="dsNomeHierarquia"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'dsNomeHierarquia' }"
        ></ng-template>
        }
      </th>
      }
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtUsuariosTooltip' | translate"
        (click)="onChangeOrdenacao('qtUsuarios')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtUsuarios' : null"
        translate
      >
        sinalizador.qtUsuarios @if (!useHierarquiaSort) {
        <p-sortIcon field="qtUsuarios"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'qtUsuarios' }"
        ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtAvaliacoesTooltip' | translate"
        (click)="onChangeOrdenacao('qtAvaliacoes')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtAvaliacoes' : null"
        translate
      >
        sinalizador.qtAvaliacoes @if (!useHierarquiaSort) {
        <p-sortIcon field="qtAvaliacoes"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'qtAvaliacoes' }"
        ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcNivelNormalidadeTooltip' | translate"
        (click)="onChangeOrdenacao('pcNivelNormalidade')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcNivelNormalidade' : null"
        translate
      >
        sinalizador.pcNivelNormalidade @if (!useHierarquiaSort) {
        <p-sortIcon field="pcNivelNormalidade"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcNivelNormalidade' }"
        ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcDefinicaoAcaoTooltip' | translate"
        (click)="onChangeOrdenacao('pcDefinicaoAcao')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcDefinicaoAcao' : null"
        translate
      >
        sinalizador.pcDefinicaoAcao @if (!useHierarquiaSort) {
        <p-sortIcon field="pcDefinicaoAcao"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcDefinicaoAcao' }"
        ></ng-template>
        }
      </th>
      @if (configuracao?.flExibeNivel) {
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcNivelAlteradoTooltip' | translate"
        (click)="onChangeOrdenacao('pcNivelAlterado')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcNivelAlterado' : null"
        translate
      >
        sinalizador.pcNivelAlterado @if (!useHierarquiaSort) {
        <p-sortIcon field="pcNivelAlterado"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcNivelAlterado' }"
        ></ng-template>
        }
      </th>
      } @if (configuracao?.flExibeAcoesCriticas) {
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcAcoesCriticasTooltip' | translate"
        (click)="onChangeOrdenacao('pcAcoesCriticas')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcAcoesCriticas' : null"
        translate
      >
        sinalizador.pcAcoesCriticas @if (!useHierarquiaSort) {
        <p-sortIcon field="pcAcoesCriticas"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcAcoesCriticas' }"
        ></ng-template>
        }
      </th>
      } @if (configuracao?.flExibeQueixas) {
      <th
        tooltipPosition="top"
        [pTooltip]="
          (isGerdau ? 'sinalizador.qtQueixasUsuariosTooltipGerdau' : 'sinalizador.qtQueixasUsuariosTooltip')
            | translate
        "
        (click)="onChangeOrdenacao('qtQueixasUsuarios')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtQueixasUsuarios' : null"
        translate
      >
        {{ isGerdau ? "sinalizador.bemEstarGerdau" : "sinalizador.bemEstar" }}
        @if (!useHierarquiaSort) {
        <p-sortIcon field="qtQueixasUsuarios"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'qtQueixasUsuarios' }"
        ></ng-template>
        }
      </th>
      } @if (configuracao?.flExibePreditorRisco) {
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcPreditorRiscoTooltip' | translate"
        (click)="onChangeOrdenacao('pcPreditorRisco')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcPreditorRisco' : null"
        translate
      >
        sinalizador.pcPreditorRisco @if (!useHierarquiaSort) {
        <p-sortIcon field="pcPreditorRisco"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcPreditorRisco' }"
        ></ng-template>
        }
      </th>
      } @if (configuracao?.flExibeFadiga) {
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcFadigaTooltip' | translate"
        (click)="onChangeOrdenacao('pcFadiga')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcFadiga' : null"
        translate
      >
        sinalizador.pcFadiga @if (!useHierarquiaSort) {
        <p-sortIcon field="pcFadiga"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'pcFadiga' }"
        ></ng-template>
        }
      </th>
      }
      <th
        data-tutorial="coluna-score-total"
        class="position-relative"
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtScoreTotalTooltip' | translate"
        (click)="onChangeOrdenacao('qtScoreTotal')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtScoreTotal' : null"
        translate
      >
        sinalizador.qtScoreTotal @if (!useHierarquiaSort) {
        <p-sortIcon field="qtScoreTotal"></p-sortIcon>
        } @if (useHierarquiaSort) {
        <ng-template
          [ngTemplateOutlet]="ordenacaoTemplate"
          [ngTemplateOutletContext]="{ $implicit: 'qtScoreTotal' }"
        ></ng-template>
        }
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-sinalizador let-rowIndex="rowIndex" let-expanded="expanded">
    @if (sinalizador.dsHierarquia) {
    <tr pRowGroupHeader>
      <td>
        @if (sinalizador.qtUsuarios > 0 && podeExpandirHierarquias) {
        <button
          type="button"
          pButton
          class="button-expand"
          [pRowToggler]="sinalizador"
          [disabled]="sinalizador.isLoading"
          (click)="expandedRowClicked.emit(sinalizador)"
          [loading]="sinalizador.isLoading"
        >
          @if (!sinalizador.isLoading) {
          <span class="p-button-icon {{ expanded ? 'pi pi-minus' : 'pi pi-plus' }}"></span>
          }
        </button>
        }
        <span class="font-bold ml-2">{{ sinalizador.dsHierarquia }}</span>
      </td>
      <ng-container *ngTemplateOutlet="colunasComuns; context: { $implicit: sinalizador }"></ng-container>
    </tr>
    }
  </ng-template>
  <ng-template [pTemplate]="showChildren ? 'rowexpansion' : 'body'" let-sinalizador>
    @if (sinalizador.dsNomeUsuario) {
    <tr [ngClass]="showChildren ? 'row-expansion' : ''">
      <td>
        {{ sinalizador.dsNomeUsuario }}
      </td>
      @if (showColunaHierarquia) {
      <td>
        {{ sinalizador.dsNomeHierarquia }}
      </td>
      }
      <ng-container *ngTemplateOutlet="colunasComuns; context: { $implicit: sinalizador }"></ng-container>
    </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="getLoadingColspan()" class="centralizar">
        <app-loader [loading]="loading" type="normal" />
        @if (!loading) {
        <span>
          {{ customErroTabela || "Nenhum registro encontrado" }}
        </span>
        }
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #colunasComuns let-sinalizador>
  <td class="centralizar">
    @if (!sinalizador.dsNomeUsuario) {
    <span> {{ sinalizador.qtUsuarios || 0 | number }}/{{ sinalizador.qtUsuariosRegra ?? 0 | number }} </span>
    }
    <img class="icone" src="assets/images/icons/user.svg" alt="Usuário icon" />
  </td>
  <td class="centralizar">
    <span> {{ sinalizador.qtAvaliacoes || 0 | number }} </span>
    <img class="icone" src="assets/images/icons/planilha.svg" alt="Planilha icon" />
  </td>
  <td>
    <div class="align-center">{{ sinalizador.pcNivelNormalidade * 100 || 0 | number }}%</div>
  </td>
  <td>
    <div class="align-center">
      {{
        sinalizador.pcDefinicaoAcao != undefined && sinalizador.pcDefinicaoAcao != null
          ? (sinalizador.pcDefinicaoAcao * 100 | number) + "%"
          : "N/A"
      }}
    </div>
  </td>
  @if (configuracao?.flExibeNivel) {
  <td>
    <div class="align-center">
      @if (showNumeroTabela) {
      <span> {{ sinalizador.pcNivelAlterado * 100 || 0 | number }}% </span>
      }
      <app-barra-energia
        [fullWidth]="true"
        comparador=">"
        class="vertical"
        [valor]="sinalizador.qtScoreNivelAlterado"
        [vlMaximo]="1"
        [pTooltip]="
          (sinalizador.qtScoreNivelAlterado == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
            | translate
        "
        tooltipPosition="top"
      />
    </div>
  </td>
  } @if (configuracao?.flExibeAcoesCriticas) {
  <td>
    <div class="align-center">
      @if (showNumeroTabela) {
      <span> {{ sinalizador.pcAcoesCriticas * 100 || 0 | number }}% </span>
      }
      <app-barra-energia
        [fullWidth]="true"
        comparador=">"
        class="vertical"
        [valor]="sinalizador.qtScoreAcoesCriticas"
        [vlMaximo]="1"
        [pTooltip]="
          (sinalizador.qtScoreAcoesCriticas == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
            | translate
        "
        tooltipPosition="top"
      />
    </div>
  </td>
  } @if (configuracao?.flExibeQueixas) {
  <td>
    <div class="align-center">
      @if (showNumeroTabela) {
      <span>
        {{ sinalizador.qtQueixasUsuarios || 0 | number : "1.2-2" }}
      </span>
      }
      <app-barra-energia
        [fullWidth]="true"
        comparador=">"
        class="vertical"
        [valor]="sinalizador.qtScoreQueixasUsuarios"
        [vlMaximo]="1"
        [pTooltip]="
          (sinalizador.qtScoreQueixasUsuarios == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
            | translate
        "
        tooltipPosition="top"
      />
    </div>
  </td>
  } @if (configuracao?.flExibePreditorRisco) {
  <td>
    <div class="align-center">
      @if (showNumeroTabela) {
      <span> {{ sinalizador.pcPreditorRisco * 100 || 0 | number }}% </span>
      }
      <app-barra-energia
        [fullWidth]="true"
        comparador=">"
        class="vertical"
        [valor]="sinalizador.qtScorePreditorRisco"
        [vlMaximo]="1"
        [pTooltip]="
          (sinalizador.qtScorePreditorRisco == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
            | translate
        "
        tooltipPosition="top"
      />
    </div>
  </td>
  } @if (configuracao?.flExibeFadiga) {
  <td>
    <div class="align-center">
      @if (showNumeroTabela) {
      <span> {{ sinalizador.pcFadiga * 100 || 0 | number }}% </span>
      }
      <app-barra-energia
        [fullWidth]="true"
        comparador=">"
        class="vertical"
        [valor]="sinalizador.qtScoreFadiga"
        [vlMaximo]="1"
        [pTooltip]="
          (sinalizador.qtScoreFadiga == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia') | translate
        "
        tooltipPosition="top"
      />
    </div>
  </td>
  }
  <td>
    <app-barra-energia
      [fullWidth]="true"
      tooltipPosition="top"
      [pTooltip]="sinalizador.qtScoreTotal + ''"
      comparador=">"
      class="horizontal"
      [valor]="sinalizador.qtScoreTotal"
      [vlMaximo]="4"
    >
    </app-barra-energia>
  </td>
</ng-template>

<ng-template #ordenacaoTemplate let-coluna>
  <span>
    @if (coluna !== sortColumn) {
    <em class="pi pi-sort-alt"></em>
    } @if (coluna == sortColumn) { @if (sortDirection == 'down') {
    <em class="pi pi-sort-amount-up-alt"></em>
    } @if (sortDirection == 'up') {
    <em class="pi pi-sort-amount-down"></em>
    } }
  </span>
</ng-template>
