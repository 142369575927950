import { TranslateService } from '@ngx-translate/core';

export enum ScoreFadigaEnum {
  BAIXO = 'BAIXO',
  MODERADO = 'MODERADO',
  ELEVADO = 'ELEVADO',
}

export function getListaScoreFadigaEnum(translateService: TranslateService): any[] {
  return [
    { label: translateService.instant(`todos`), value:  null},
    { label: translateService.instant(`scoreFadigaEnum.${ScoreFadigaEnum.BAIXO}`), value:  ScoreFadigaEnum.BAIXO},
    { label: translateService.instant(`scoreFadigaEnum.${ScoreFadigaEnum.MODERADO}`), value:  ScoreFadigaEnum.MODERADO},
    { label: translateService.instant(`scoreFadigaEnum.${ScoreFadigaEnum.ELEVADO}`), value:  ScoreFadigaEnum.ELEVADO},
  ]
}
