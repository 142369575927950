import { Component, OnDestroy, OnInit } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Subscription } from "rxjs";
import { AlertaSegurancaService } from "src/app/shared/api-services/alerta-seguranca.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { SimNaoEnum } from "src/app/shared/enums/sim-nao-enum";
import { TipoMessageEnum } from "src/app/shared/enums/tipo-message.enum";
import { MessageService } from "src/app/shared/services/message.service";
import { RoleService } from "src/app/shared/services/role.service";

@Component({
  selector: "app-dashboard-container",
  templateUrl: "./dashboard-container.component.html",
  styleUrls: ["./dashboard-container.component.scss"],
})
export class DashboardContainerComponent implements OnInit, OnDestroy {
  exibirColunaTestes = true;
  isAlerta: boolean = false;
  readOnly: boolean;
  podeVerPendentestes: boolean;
  sub: Subscription;

  constructor(
    private keyCloakService: KeycloakService,
    private messageService: MessageService,
    private alertaService: AlertaSegurancaService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.verificarPodeVerTestesPendentestes();
    this.verificarAlerta();
    this.sub = this.messageService
      .listen<boolean>(TipoMessageEnum.DASHBOARD_READONLY)
      .subscribe((response) => (this.readOnly = response.data));
  }

  verificarAlerta() {
    this.alertaService.getAlertaSeguranca().subscribe((r) => {
      this.isAlerta = r?.flAlerta == SimNaoEnum.SIM;
      if (this.isAlerta) {
        this.setCorBackground("#fff3c1");
      }
    });
  }

  private verificarPodeVerTestesPendentestes() {
    this.podeVerPendentestes = this.roleService.isRole(RoleService.ACAO_DEFINIDA_REGEX);
  }

  toggleColunaTestes() {
    this.exibirColunaTestes = !this.exibirColunaTestes;
  }

  setCorBackground(cor: string) {
    const html = document.getElementsByTagName("html")[0];
    html.style.backgroundColor = cor;
  }

  ngOnDestroy() {
    this.setCorBackground(null);
    this.sub && this.sub.unsubscribe();
  }
}
