import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestIncluirAvisoAusencia } from '../tos/response/request-incluir-aviso-ausencia';
import { ResponseHistoricoFeriasTO } from '../tos/response/response-historico-ferias.to';

@Injectable({
  providedIn: 'root'
})

export class UsuarioHistoricoAusenciaService {

  constructor(private httpClient: HttpClient) { }

  private versionAPI: string = '/v1';
  private path: string = '/usuario-historico-ausencia';

  incluirAvisoAusencia(req: RequestIncluirAvisoAusencia): Observable<any> {
    return this.httpClient.post<any>(`${this.versionAPI}${this.path}`, req);
  }

  getHistoricoByIdUsuario(idUsuario: number): Observable<ResponseHistoricoFeriasTO[]> {
    return this.httpClient.get<ResponseHistoricoFeriasTO[]>(`${this.versionAPI}${this.path}/usuario/${idUsuario}`).pipe(
      map(r => {
        r.forEach(hist => {
          hist.dtFimFerias = hist.dtFimFerias ? new Date(hist.dtFimFerias + ' 23:59') : null;
          hist.dtFimFeriasCadastrada = hist.dtFimFeriasCadastrada ? new Date(hist.dtFimFeriasCadastrada + ' 23:59') : null;
          hist.dtInicioFerias = hist.dtInicioFerias ? new Date(hist.dtInicioFerias + ' 23:59') : null;
          hist.dtInclusao = hist.dtInclusao ? new Date(hist.dtInclusao) : null;
        });
        return r;
      })
    );
  }

  atualizarAvisoAusencia(req: RequestIncluirAvisoAusencia, idAviso: number): Observable<any> {
    return this.httpClient.put<any>(`${this.versionAPI}${this.path}/${idAviso}`, req);
  }

  deletarAvisoAusencia(idAviso: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.versionAPI}${this.path}/${idAviso}`);
  }
}

