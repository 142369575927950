import { ResponseResumoTestesExecutadosDia } from './../../../../shared/models/response-resumo-testes-executados-dia.to';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resumo-badge-content',
  templateUrl: './resumo-badge-content.component.html',
  styleUrls: ['./resumo-badge-content.component.scss']
})
export class ResumoBadgeContentComponent implements OnInit {
  @Input() resumo: ResponseResumoTestesExecutadosDia;

  constructor() { }

  ngOnInit(): void {
  }

}
