import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TesteExecutadoDadoUltimos100 } from '../models/teste-executado-dado-ultimos-100.model';

@Injectable({
  providedIn: 'root'
})

export class ResultadosService {

  constructor(private httpClient: HttpClient) { }

  getResultados(): Observable<TesteExecutadoDadoUltimos100[]> {
    const retorno = this.httpClient.get<TesteExecutadoDadoUltimos100[]>(`/v1/teste-executado/ultimos100`);
    return retorno;
  }
}

