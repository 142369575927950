import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TipoComparacaoFadigaEnum } from "src/app/shared/enums/TipoComparacaoFadiga.enum";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { FiltroIndicadorFadiga } from "src/app/shared/models/filtro-indicador-fadiga";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { ResponseGraficoIndicadorFadigaTO } from "src/app/shared/tos/response/response-grafico-indicador-fadiga.to";
import { DadosUsuarioIndicadorFadigaTo } from "src/app/shared/tos/response/response-usuarios-indicador-fadiga.to";
import CORES_NIVEL_FADIGA from "./cores-nivel-fadiga";
import { Util } from "src/app/shared/services/util.service";

enum TipoGraficoAlias {
  DIARIO = "DIARIO",
  INTERJORNADA = "INTERJORNADA",
  INTRAJORNADA = "INTRAJORNADA",
}
@Component({
  selector: "app-indicadores-fadiga",
  templateUrl: "./indicadores-fadiga.component.html",
  styleUrls: ["./indicadores-fadiga.component.scss"],
})
export class IndicadoresFadigaComponent implements OnChanges {
  @Input() filtros: FiltroGraficoDashboad;
  @Input() dados: ResponseGraficoIndicadorFadigaTO[];
  graficoDiario: ResponseGraficoIndicadorFadigaTO;
  graficoInterjornada: ResponseGraficoIndicadorFadigaTO;
  graficoIntrajornada: ResponseGraficoIndicadorFadigaTO;
  graficoSelecionado: TipoComparacaoFadigaEnum;

  paginaUsuarios = 1;
  qtdItensPagina = 30;
  qtdTotal: number;
  loadingUsuarios = false;
  dadosIndicadorFadiga: DadosUsuarioIndicadorFadigaTo[];
  usuarioSelecionado: DadosUsuarioIndicadorFadigaTo;

  constructor(private dashboardService: DashboardService, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dados?.currentValue) {
      this.separarGraficosFadiga();
    }
  }

  separarGraficosFadiga() {
    const compararTipo = (tipo: TipoComparacaoFadigaEnum) =>
      this.dados.find((d) => d.cdTipoComparacaoFadiga == tipo);
    if (!this.dados) {
      this.graficoDiario = null;
      this.graficoInterjornada = null;
      this.graficoIntrajornada = null;
    } else {
      this.graficoDiario = compararTipo(TipoComparacaoFadigaEnum.FADIGA_DIARIO);
      this.graficoInterjornada = compararTipo(TipoComparacaoFadigaEnum.FADIGA_INTERJORNADA);
      this.graficoIntrajornada = compararTipo(TipoComparacaoFadigaEnum.FADIGA_INTRAJORNADA);
    }
  }

  selecionarGrafico(tipo: TipoGraficoAlias) {
    this.graficoSelecionado = null;
    switch (tipo) {
      case "DIARIO":
        this.graficoSelecionado = TipoComparacaoFadigaEnum.FADIGA_DIARIO;
        break;
      case "INTERJORNADA":
        this.graficoSelecionado = TipoComparacaoFadigaEnum.FADIGA_INTERJORNADA;
        break;
      case "INTRAJORNADA":
        this.graficoSelecionado = TipoComparacaoFadigaEnum.FADIGA_INTRAJORNADA;
        break;
    }
  }

  handleGraficoSelecionado(tipo: TipoGraficoAlias) {
    this.selecionarGrafico(tipo);
    this.paginaUsuarios = 1;
    this.buscarListaUsuarios(this.graficoSelecionado);
  }

  isTermometroSelecionado(tipo: TipoGraficoAlias) {
    return (
      (tipo == "DIARIO" && this.graficoSelecionado == TipoComparacaoFadigaEnum.FADIGA_DIARIO) ||
      (tipo == "INTERJORNADA" && this.graficoSelecionado == TipoComparacaoFadigaEnum.FADIGA_INTERJORNADA) ||
      (tipo == "INTRAJORNADA" && this.graficoSelecionado == TipoComparacaoFadigaEnum.FADIGA_INTRAJORNADA)
    );
  }

  handlePageChange(page: number) {
    this.paginaUsuarios = page;
    this.buscarListaUsuarios(this.graficoSelecionado);
  }

  buscarListaUsuarios(tipo: TipoComparacaoFadigaEnum) {
    this.loadingUsuarios = true;
    const filtros = new FiltroIndicadorFadiga().convertFromFiltroDashbaord(this.filtros);
    this.dashboardService
      .getUsuariosIndicadorFadiga(filtros, this.paginaUsuarios, this.qtdItensPagina, tipo)
      .subscribe(
        (r) => {
          this.loadingUsuarios = false;
          this.qtdTotal = r.qtTotalRegistros;
          this.dadosIndicadorFadiga = r.dados;
        },
        (err) => {
          this.loadingUsuarios = false;
          throw err;
        }
      );
  }

  handleUsuarioSelecionado(usuario: DadosUsuarioIndicadorFadigaTo) {
    this.usuarioSelecionado = usuario;
  }

  isUsuarioSelected(usuario: DadosUsuarioIndicadorFadigaTo) {
    return this.usuarioSelecionado?.idUsuario == usuario.idUsuario;
  }

  getInicioTabela(nrTabela: number) {
    return (nrTabela - 1) * Math.ceil(this.qtdItensPagina / 3);
  }

  getFimTabela(nrTabela: number) {
    return nrTabela * Math.ceil(this.qtdItensPagina / 3);
  }

  get tituloTabela() {
    switch (this.graficoSelecionado) {
      case TipoComparacaoFadigaEnum.FADIGA_DIARIO:
        return this.translateService.instant("indicadorFadiga.diario");
      case TipoComparacaoFadigaEnum.FADIGA_INTERJORNADA:
        return this.translateService.instant("indicadorFadiga.interjornada");
      case TipoComparacaoFadigaEnum.FADIGA_INTRAJORNADA:
        return this.translateService.instant("indicadorFadiga.intrajornada");
    }
  }

  getFadiga(nivel: number) {
    return CORES_NIVEL_FADIGA.getIndicadorByNivel(nivel);
  }

  isAnaliseLongoPeriodo(usuario: DadosUsuarioIndicadorFadigaTo) {
    return usuario.qtHorasComparacaoFadiga > 24;
  }

  acessarPrimeiroCard() {
    if (this.graficoDiario) {
      this.handleGraficoSelecionado(TipoGraficoAlias.DIARIO);
    }
  }
}
