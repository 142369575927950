import { OnInit, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SimpleCrudPage } from "../simple-crud-page";
import { IsMobileService } from "src/app/shared/services/is-mobile.service";
import { UsuarioService } from "src/app/shared/api-services/usuario.service";
import { ResponseUsuarioSimplesTO } from "src/app/shared/tos/response/response-usuario-simple.to";
import { UntypedFormGroup, NgForm } from "@angular/forms";
import { NotificationService } from "src/app/shared/services/notification.service";
import { RequestUsuarioFeriasTO } from "src/app/shared/tos/request/request-usuario-ferias.to";
import { Util } from "src/app/shared/services/util.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { UsuarioHistoricoAusenciaService } from "src/app/shared/api-services/usuario-historico-ausencia.service";
import { RequestIncluirAvisoAusencia } from "src/app/shared/tos/response/request-incluir-aviso-ausencia";
import { ResponseHistoricoFeriasTO } from "src/app/shared/tos/response/response-historico-ferias.to";
import { HistoricoFeriasComponent } from "./historico-ferias/historico-ferias.component";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { UsuarioFilter } from "src/app/shared/filters/usuario-filter";
import { TreeNode } from "primeng/api";
import { SelectItem } from "src/app/shared/models/select-item.model";
import { TurnoService } from "src/app/shared/api-services/turno.service";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { ClienteUtil } from "src/app/shared/util/cliente.util";
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from "primeng/autocomplete";
import {
  TipoJustificativaAusenciaEnum,
  getTipoJustificativasAusencia,
} from "src/app/shared/enums/tipo-justificativa-ausencia.enum";

@Component({
  selector: "app-aviso-de-ausencia",
  templateUrl: "./aviso-de-ausencia.component.html",
  styleUrls: ["./aviso-de-ausencia.component.scss"],
})
export class AvisoAusenciaComponent extends SimpleCrudPage implements OnInit {
  @ViewChild(HistoricoFeriasComponent) historicoFeriasComponente: HistoricoFeriasComponent;

  titulo: string;

  load: boolean;

  loading: boolean = false;
  loadButton: boolean;

  primeiroDiaMes: any;
  ultimoDiaMes: any;
  desktopDevice: boolean;
  dadosFiltro: RequestIncluirAvisoAusencia = new RequestIncluirAvisoAusencia();
  usuarios: ResponseUsuarioSimplesTO[];
  isAvisoFeriasVisible: boolean;
  formFerias: UntypedFormGroup;
  requestUsuarioFerias: RequestUsuarioFeriasTO;
  usarioSelecionado: ResponseUsuarioSimplesTO;
  usarioSelecionadoByMatricula: ResponseUsuarioSimplesTO;
  usuariosSelecionados: ResponseUsuarioSimplesTO[] = [];

  avisoEdicao: ResponseHistoricoFeriasTO;

  hierarquias: TreeNode[];
  turnos: SelectItem[];
  justificativas: SelectItem[];

  constructor(
    private translateService: TranslateService,
    activatedRouter: ActivatedRoute,
    private isMobileService: IsMobileService,
    private usuariosService: UsuarioService,
    private notificationService: NotificationService,
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private avisoAusenciaService: UsuarioHistoricoAusenciaService,
    private turnoService: TurnoService,
    private hierarquiaService: HierarquiaService
  ) {
    super(activatedRouter);
  }

  ngOnInit() {
    this.notificarAviso();
    this.detectarDispositivo();
    this.tituloAvisoFerias();
    this.carregarDataInicial();
    this.getHierarquias();
    this.carregarJustificativas();
  }

  carregarJustificativas() {
    this.justificativas = getTipoJustificativasAusencia(this.translateService);
  }

  notificarAviso() {
    this.notificationService.notifyWarning(
      this.translateService.instant(
        ClienteUtil.isNxGold() ? "avisoFerias.notificarInativacoesNxGold" : "avisoFerias.notificarInativacoes"
      )
    );
  }

  getTurnos(idHierarquia: number[]) {
    this.turnos = [];
    this.turnoService.getByHierarquia(idHierarquia).subscribe(
      (r) => {
        this.turnos = Util.jsonArrayToSelectItem(r, "dsTurno", null, "id") as SelectItem[];
      },
      (err) => {
        throw err;
      }
    );
  }

  getHierarquias() {
    this.hierarquiaService.getAll().subscribe(
      (r) => {
        this.hierarquias = this.hierarquiaService.createTreeView(r);
      },
      (err) => {
        throw err;
      }
    );
  }

  carregarDataInicial() {
    const date = new Date();
    this.primeiroDiaMes = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dadosFiltro.dtInicioFerias = new Date();
    this.dadosFiltro.dtInicioFerias.setHours(0, 0, 0);
    this.dadosFiltro.dtFimFerias = new Date();
    this.dadosFiltro.dtFimFerias.setHours(23, 59, 59);
  }

  /**
   * Pesquisa na base de dados todas as ocorrências do nome informado.
   *
   * @param query Parte do nome informado pelo usuários
   */
  pesquisarUsuarios(event: AutoCompleteCompleteEvent): void {
    this.avisoEdicao = null;
    this.resetUsuarioSelecionado("usuario");
    this.usuariosService
      .getByPartName(event.query, { situacao: "ATIVO", tipoUsuario: [Permissao.OPERADOR] } as any)
      .subscribe((usuarios) => {
        this.usuarios = usuarios.dados;
      });
  }

  pesquisarUsuariosByMatricula(event): void {
    const filtros = new UsuarioFilter();
    filtros.matricula = event.query;
    filtros.nome = event.query;
    filtros.situacao = "ATIVO";
    filtros.tipoUsuario = [Permissao.OPERADOR];
    this.resetUsuarioSelecionado("matricula");
    this.avisoEdicao = null;
    this.usuariosService.getAll(filtros).subscribe((usuarios) => {
      this.usuarios = usuarios.dados;
    });
  }

  resetUsuarioSelecionado(type: "usuario" | "matricula" | "hierarquia" | "turno") {
    if (type === "usuario") {
      this.usarioSelecionadoByMatricula = null;
      this.dadosFiltro.idHierarquia = null;
      this.dadosFiltro.flIncluirFilhas = false;
      this.dadosFiltro.idTurno = null;
    } else if (type == "matricula") {
      this.usarioSelecionado = null;
      this.dadosFiltro.idHierarquia = null;
      this.dadosFiltro.flIncluirFilhas = false;
      this.dadosFiltro.idTurno = null;
    } else {
      this.usarioSelecionadoByMatricula = null;
      this.usarioSelecionado = null;
      this.usuariosSelecionados = [];
    }
  }

  appendListaUsuario(item: AutoCompleteSelectEvent) {
    const usuarioSelecionado = this.usuariosSelecionados.find((u) => u.id == item.value?.id);
    this.resetUsuarioSelecionado("usuario");
    this.resetUsuarioSelecionado("matricula");
    if (!usuarioSelecionado) {
      this.usuariosSelecionados.push(item.value);
    }
  }

  removeUsuario(index: number) {
    this.usuariosSelecionados.splice(index, 1);
  }

  tituloAvisoFerias(): void {
    this.titulo = this.translateService.instant("avisoFerias.titulo");
  }

  salvarFerias(requestUsuarioFeriasTO: RequestIncluirAvisoAusencia): void {
    this.loading = true;
    this.avisoAusenciaService.incluirAvisoAusencia(requestUsuarioFeriasTO).subscribe(
      () => {
        this.finalizarSalvar("atualizadoSucesso");
        this.cancelarEdicao();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      }
    );
  }

  editarFerias(request: RequestIncluirAvisoAusencia, idAviso: number) {
    this.loading = true;
    this.avisoAusenciaService.atualizarAvisoAusencia(request, idAviso).subscribe(
      () => {
        this.finalizarSalvar("atualizadoSucesso");
        this.cancelarEdicao();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      }
    );
  }

  submit(): void {
    if (
      !this.usuariosSelecionados?.length &&
      !this.dadosFiltro.idHierarquia?.length &&
      !this.dadosFiltro.idTurno?.length
    ) {
      this.notificationService.notifyError(
        this.translateService.instant("avisoFerias.textoErroSelecionarUsuario")
      );
      this.usarioSelecionado = null;
      this.usarioSelecionadoByMatricula = null;
      return;
    }

    if (this.dadosFiltro.idHierarquia?.length) {
      this.notificationService.notify(
        this.translateService.instant("avisoFerias.notificarHierarquiaSelecionada"),
        NotificationType.WARNING,
        null,
        () => this.handleSalvar(),
        () => {}
      );
    } else {
      this.handleSalvar();
    }
  }

  shouldShowObservacao() {
    return this.dadosFiltro.tpJustificativa == TipoJustificativaAusenciaEnum.OUTROS;
  }

  handleSalvar() {
    const requestUsuarioFerias: RequestIncluirAvisoAusencia = new RequestIncluirAvisoAusencia();
    requestUsuarioFerias.dtInicioFerias = Util.dateToStringByFormat(
      this.dadosFiltro.dtInicioFerias,
      "YYYY-MM-DD"
    );
    requestUsuarioFerias.dtFimFerias = Util.dateToStringByFormat(this.dadosFiltro.dtFimFerias, "YYYY-MM-DD");
    requestUsuarioFerias.dsObservacaoAusencia = this.dadosFiltro.dsObservacaoAusencia;
    requestUsuarioFerias.tpJustificativa = this.dadosFiltro.tpJustificativa;
    requestUsuarioFerias.idUsuario = this.usuariosSelecionados.map((usuario) => usuario.id);
    requestUsuarioFerias.idHierarquia = this.dadosFiltro.idHierarquia;
    requestUsuarioFerias.idTurno = this.dadosFiltro.idTurno;
    requestUsuarioFerias.flIncluirFilhas = this.dadosFiltro.flIncluirFilhas;

    if (this.avisoEdicao && this.avisoEdicao.id) {
      this.editarFerias(requestUsuarioFerias, this.avisoEdicao.id);
    } else {
      this.salvarFerias(requestUsuarioFerias);
    }
  }

  isSubmitEnabled(form: NgForm) {
    return (
      form.valid &&
      (this.usuariosSelecionados?.length ||
        (!this.avisoEdicao && (this.dadosFiltro?.idHierarquia?.length || this.dadosFiltro?.idTurno?.length)))
    );
  }

  getCalendarTranslate() {
    return this.translateService.instant("calendar");
  }

  getTraducao(chave: string): string {
    return this.translateService.instant(chave);
  }

  finalizarSalvar(mensagemRetorno: string) {
    this.toastService.notifyWithTranslateSuccess(mensagemRetorno);
    this.inactivateLoader();
  }

  detectarDispositivo() {
    if (this.isMobileService.isMobile()) {
      this.desktopDevice = false;
    } else {
      this.desktopDevice = true;
    }
  }

  onHierarquiaSelect(node?: number[]) {
    if (node) {
      this.getTurnos(node);
    }
    this.resetUsuarioSelecionado("hierarquia");
  }

  cancelarEdicao() {
    this.avisoEdicao = null;
    this.usarioSelecionado = null;
    this.usarioSelecionadoByMatricula = null;
    this.usuariosSelecionados = [];
    this.dadosFiltro = new RequestIncluirAvisoAusencia();
    this.dadosFiltro.dtInicioFerias = new Date();
    this.dadosFiltro.dtInicioFerias.setHours(0, 0, 0);
    this.dadosFiltro.dtFimFerias = new Date();
    this.dadosFiltro.dtFimFerias.setHours(23, 59, 59);
    this.dadosFiltro.flIncluirFilhas = false;
  }

  editarAviso(aviso: ResponseHistoricoFeriasTO) {
    const observacaoPredefinida = this.justificativas.find(
      (justificativa) => justificativa.value == aviso.dsObservacaoAusencia
    );
    this.avisoEdicao = aviso;
    this.dadosFiltro.dtFimFerias = aviso.dtFimFerias;
    this.dadosFiltro.dtInicioFerias = aviso.dtInicioFerias;
    this.dadosFiltro.dsObservacaoAusencia = null;
    this.usuariosSelecionados = [this.usuariosSelecionados[0]];
    if (observacaoPredefinida) {
      this.dadosFiltro.tpJustificativa = observacaoPredefinida?.value;
    } else {
      this.dadosFiltro.tpJustificativa = TipoJustificativaAusenciaEnum.OUTROS;
      this.dadosFiltro.dsObservacaoAusencia = aviso.dsObservacaoAusencia;
    }
    window.scrollTo(0, 0);
  }

  visualizarHistorico(usuario: ResponseUsuarioSimplesTO) {
    this.historicoFeriasComponente.abrirDialog(usuario);
  }

  isHierarquiaDisabled() {
    return this.usuariosSelecionados?.length;
  }

  isUsuarioDisabled() {
    return this.avisoEdicao || this.dadosFiltro?.idHierarquia?.length;
  }
}
