import { ResponseSinalizadorProntidaoItemTO } from 'src/app/shared/tos/response/response-sinalizador-prontidao-hierarquia-item.to';
import { ResponseSinalizadorProntidaoUsuarioItemTO } from 'src/app/shared/tos/response/response-sinalizador-prontidao-usuario-item.to';
export class LinhaTabelaSinalizadorProntidao {
  nmUsuario: string;
  idUsuario: number;
  qtAvaliacoes: number;
  qtUsuarios: number;
  pcNivelAlterado: number;
  pcAcoesCriticas: number;
  pcPreditorRisco: number;
  qtScore: number;
  idHierarquia: number;
  dsHierarquia: string;
  dsSigla: string;
  idHierarquiaSuperior: number;
  qtQueixasUsuarios: number;
  qtScoreTotal: number;
  qtScoreNivelAlterado: number;
  qtScoreAcoesCriticas: number;
  qtScoreQueixasUsuarios: number;
  qtScorePreditorRisco: number;

  usuarios = []
  usuariosCarregados = false;
  isLoading = false;

  fromResponse(response: ResponseSinalizadorProntidaoItemTO) {
    const linha = Object.assign(this, response);
    return linha;
  }

  fromUsuarioByHierarquiaResopnse(response: ResponseSinalizadorProntidaoUsuarioItemTO) {
    const linha = Object.assign(this, response);
    return linha;
  }

}
